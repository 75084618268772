import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const limit = 20
const App=({context,id,url,name,setImg,setPDF})=>{

  const handleCapture = (e) => {
    if (e.files) {
      if (e.files.length !== 0) {
        if (e.files[0].size>=limit) {
          context.setModalShow({
                          show:true,
                          message:  <div>
                                        Imagen supera límite permitido de ({limit/1000000}) MB
                                        <Button fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={()=>context.setModalShow(false)}>Reintentar con otra</Button>
                                    </div>,
                          size:""
                        }
                      )
        }
        context.setModalShow({
                        show:true,
                        message:"Cargando imagen al sistema",
                        size:""
                      }
                    )
        const uri     =   (url)?url:            "api/comment/uploadfile";
        context.post(uri,{images:e.files[0],id:id,name:name},false,true).then((response)=>{
          if (response.data && context.socket) {
            //context.socket.emit('registerChat',{data:response.data,id:id,user_id:context.user.id});
          }
          if (setImg && response.data) {
            //setImg(response.data)


            if (response.data.includes("pdf")) {
              setPDF(response.data)
            }else if (response.data.includes("png")) {
              setImg(response.data)
            }else if (response.data.includes("gif")) {
              setImg(response.data)
            }else if (response.data.includes("jpg")) {
              setImg(response.data)
            }else if (response.data.includes("jpeg")) {
              setImg(response.data)
            }

          }
        })
      }
    }
  };
  return  <>
            {context?<Grid className="cam-content cursor-pointer">
                        <AddPhotoAlternateIcon className="cursor-pointer"/>
                        <input  accept='application/pdf,image/*'
                                id='icon-button-file'
                                type='file'
                                capture='environment'
                                onChange={(e) => handleCapture(e.target)}/>
                      </Grid>:false}
          </>
}
export default App
