import Grid from '@mui/material/Grid';
import Table from '../common/Table';

const td  = [
              {
                label:"Título",
                value:"name",
                align:"left"
              },
              {
                label:"Componente",
                value:"components",
                align:"left"
              },
              {
                label:"Componente id",
                value:"component_id",
                align:"left"
              },
              {
                label:"Estatus",
                value:"status",
                align:"left"
              },
              {
                label:"Slug",
                value:"slug",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["view","activity"]
              },
            ]

const App = ({usePrivileges,endpoint})=>{
  return  <Grid container
                component="main"
                direction="row"
                className="min-height-80"
                justifyContent="center"
                >

            <Grid item xs={11}>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
                  <Table td={td} endpoint={endpoint?endpoint:false}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
