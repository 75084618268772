import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Paper    from '@mui/material/Paper';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import StateContext from '../../helpers/ContextState';
import Select from '../common/Select';
import Loading from '../common/Loading01';
import Button from '@mui/material/Button';
import {init,set} from '../../helpers/fninputs';

import { useNavigate } from "react-router-dom";
import Textarea from '../common/TextareaCustom';
import ContentGallery from '../multimedia/ContentGallery';
import Carousel    from '../../web/components/Carousel';

const btnSubmitId    =   'btnSubmitId'
let getInit

const App=()=>{
  const navigate                              = useNavigate();
  const context                               = React.useContext(StateContext);
  const [loading, setLoading]                 = useState(true);
  const [inputs, setInputs]                   = useState({});
  const [categories, setCategories]           = useState({});
  const [gallery, setGallery]                 = useState([]);
  const [slider, setSlider]                   = useState([]);

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{

    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data && response.data.content) {
        setInputs(response.data.content)
      }
      if (response.data && response.data.content &&  response.data.content.gallery) {
        setGallery(JSON.parse(response.data.content.gallery))
      }
      if (response.data && response.data.categories) {
        setCategories(response.data.categories)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()

    context.post(false,{  ...inputs,gallery:JSON.stringify(gallery),slider:JSON.stringify(slider)},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        navigate('/contents/add/'+response.data.id)
      }
    })
  }

  const handleGallery=(event,filter)=>{
    context.setModalShow({
                            show:true,
                            message:  <Grid sx={{height:"80vh"}}>
                                        <ContentGallery event={event} context={context} filter={filter}/>
                                      </Grid>,
                            width:"90%"
                          }
                        )
  }

  const eventOne=(row)=>{
    gallery.push(row)
    setGallery(gallery)
  }

  const eventTwo=(row)=>{
    slider.push(row)
    setSlider(slider)
  }

  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: {xs:5,md:10}, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={11} sm={11} md={11} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Paper sx={{ p: 2, }}>
                        <Carousel data={gallery}/>
                        <Grid container spacing={0}>
                          <Grid  item xs={12} md={4}>
                            <input type="hidden" name="category" value="junta_directiva"/>
                            <Input  margin="normal"
                                    required
                                    type="text"
                                    defaultValue={inputs.title}
                                    label="Nombre"
                                    name="title"
                                    onChange={set}
                                    />
                          </Grid>
                          <Grid  item xs={12} md={2}>
                            <Input  margin="normal"
                                    required
                                    type="text"
                                    defaultValue={inputs.gallery}
                                    label="Orden"
                                    name="gallery"
                                    onChange={set}
                                    />
                          </Grid>
                          <Grid  item xs={12}>
                            <Textarea name="content" inputs={inputs} setInputs={setInputs}/>
                          </Grid>
                          <Grid  item xs={12}>
                            <Button fullWidth
                                    variant="contained"
                                    type="submit"
                                    sx={{ mt: 3, mb: 2 }}>Guardar</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
