import * as React from 'react';
import StateContext from '../../helpers/ContextState';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
const Modal=({context,eventDelete})=>{
  return  <Grid container sx={{ flexGrow: 1 }} justifyContent="center">
            <Grid  item xs={12} >
              ¿Desea eliminar este regisrto?
            </Grid>
            <Stack direction="row" spacing={2}>
              <Button  variant="contained" color="secondary" onClick={eventDelete}>Si</Button>
              <Button  variant="contained" color="grey" onClick={()=>context.setModalShow(false)}>No</Button>
            </Stack>
          </Grid>
}
const App=({row,uri})=>{
  const context  = React.useContext(StateContext);
  const handleDelete=()=>{
    context.setModalShow({
                            show:true,
                            message:<Modal context={context} eventDelete={eventDelete}/>,
                          }
                        )
  }
  const eventDelete=()=>{
    context.post(uri,{id:row.id},true,true).then((response)=>{
      if (response && response.data) {
        console.log(response.data);
      }
    })
  }
  return  <DeleteIcon className="cursor-pointer" onClick={handleDelete}/>
}
export default App
