import React from 'react';
import { useEffect } from 'react';
import StateContext from '../../helpers/ContextState';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
//import { NavLink } from "react-router-dom";
import {
          inputs,
          useInputs
        } from '../../helpers/useInputs';

const Login=()=>{

  const context           =   React.useContext(StateContext);
  const navigate          =   useNavigate();

  useEffect(()=>{
    document.title =  process.env.REACT_APP_NAME + " - inicio de sesión"
  },[])

  const handleSubmit=(e)=>{
    e.preventDefault()
    context.post("api/login",inputs,false,true).then((response)=>{
      if (response.data && response.data.user) {
        // context.socket.emit("registeruser",{  ...response.data.user,
        //                                       latitude:context.position.latitude,
        //                                       longitude:context.position.longitude
        //                                     })
        document.title =  process.env.REACT_APP_NAME + " - hola " +response.data.user.name
        context.setUser({...response.data.user,access_token:response.data.access_token})
        context.setToken(response.data.access_token)
        navigate("/user")
      }
    })
  }

  return <>
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <form  onSubmit={handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="email"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={useInputs}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={useInputs}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}> Iniciar sesión </Button>
            </Box>
          </form>
        </>
}

export default Login
