import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo1 from '../../../assets/images/logo.gif';
import logo2 from '../../../assets/images/logo-pse.png';
import {modules_menu} from '../../../helpers/modules';
import { Link,NavLink } from "react-router-dom";
import ItemSubMenu from './ItemSubMenu';
import {open} from '../../../helpers/fninputs';
import StateContext from '../../../helpers/ContextState';


const ResponsiveAppBar = ({position,user}) => {
  const context                           =   React.useContext(StateContext);
  const [anchorElNav, setAnchorElNav]     =   React.useState(null);
  const [anchorElUser, setAnchorElUser]   =   React.useState(null);
  const [modules_web, setModules_web]     =   React.useState([]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (row) => {
    seo(row)
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (row) => {
    seo(row)
    setAnchorElUser(null);
  };

  const seo=(row)=>{
    document.title =  process.env.REACT_APP_NAME+" - "+row.name
  }

  React.useEffect(()=>{
    if (context.menus &&context.menus.menu) {
      setModules_web(context.menus.menu)
    }
  },[context.menus]);


  return (
    <AppBar position={position} color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{display: { xs: 'none', md: 'flex' }, mr: 1,p:2 }}>
            <a href="https://fesol.com.co/" style={{ textDecoration: 'none' }}>
              <img width={"120"} height={"100"} src={"https://fesol.com.co/static/media/logo-fesol-home-programandoweb.42d743f448bb4a63bbbc88319a62b49b.svg"} alt={process.env.REACT_APP_NAME+" PSE Programandoweb"} className="logo"  />
            </a>
          </Box>
          <Box sx={{display: { xs: 'none', md: 'flex' }, mr: 1,p:2 }}>
            <div className="cursor-pointer" onClick={()=>open("https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=11429")}>
              <img src={logo2} alt={process.env.REACT_APP_NAME+" PSE Programandoweb"} className="logo"  />
            </div>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {modules_web.map((page,key) =>{
                  if (page.menu===false) {
                      return <div key={key}></div>
                  }
                  if (page.items===undefined) {
                      return <MenuItem  key={key}
                                        component={Link}
                                        className={page.className}
                                        to={page.slug}
                                        onClick={()=>handleCloseUserMenu(page)}>
                              {page.Icon?<page.Icon></page.Icon>:false}
                              <Typography textAlign="center">
                                {page.name}
                              </Typography>
                            </MenuItem>
                  }else {
                    return <ItemSubMenu page={page} handleCloseNavMenu={handleCloseNavMenu} label={page.name} data={page.items} key={key}></ItemSubMenu>
                  }
                }
              )}
                <MenuItem component={Link} to={'/'}>
                  <Typography textAlign="center">
                      Beneficios
                    </Typography>
                </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img src={logo1} alt="Logo Programandoweb" className="logo-xs"  />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {modules_web.map((page,key) =>{
                if (page.menu===false) {
                    return <div key={key}></div>
                }
                if (page.items===undefined) {
                    return <MenuItem  key={key}
                                      component={Link}
                                      className={page.className}
                                      to={page.slug}
                                      onClick={()=>handleCloseUserMenu(page)}>
                            {page.Icon?<page.Icon></page.Icon>:false}
                            <Typography textAlign="center">
                              {page.name}
                            </Typography>
                          </MenuItem>
                }else {
                  return <ItemSubMenu page={page} handleCloseNavMenu={handleCloseNavMenu} label={page.name} data={page.items} key={key}></ItemSubMenu>
                }
              }
            )}
            <MenuItem component={Link} to={'/beneficios'}>
              <Typography textAlign="center">
                  Beneficios
                </Typography>
            </MenuItem>
          </Box>

          {user?<>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Mi Perfil">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.name} src={user&&user.avatar?user.avatar:"https://www.sanboni.edu.co/onu/wp-content/uploads/avatar-mujer.png"} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {
                      modules_menu.map((setting,key) =>
                        {
                          if (setting.menu) {
                            return  <MenuItem key={key}
                                              component={Link}
                                              to={setting.slug.replace('/:id', '')+'/'+user.token}
                                              onClick={()=>handleCloseUserMenu(setting)}>
                                      {setting.Icon?<setting.Icon></setting.Icon>:false}
                                      <Typography textAlign="center">{setting.name}</Typography>
                                    </MenuItem>
                          }else {
                            return false
                          }
                        }
                      )
                  }

                </Menu>
              </Box>

            </>:<Box sx={{ flexGrow: 0 }}>
                  <MenuItem   component={Link}
                              className={""}
                              to={"/user"}>
                    <Typography textAlign="center">
                      Área de usuarios
                    </Typography>
                  </MenuItem>
            </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
