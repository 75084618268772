import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper    from '@mui/material/Paper';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import StateContext from '../../helpers/ContextState';
import Select from '../common/Select';
import Loading from '../common/Loading01';
import Button from '@mui/material/Button';
import {init,set} from '../../helpers/fninputs';

import { useNavigate } from "react-router-dom";



const btnSubmitId    =   'btnSubmitId'
let getInit



const App=()=>{
  const navigate                              = useNavigate();
  const context                               = React.useContext(StateContext);
  const [loading, setLoading]                 = useState(true);
  const [inputs, setInputs]                   = useState({});
  const [categories, setCategories]           = useState({});
  const [components, setComponents]           = useState([]);
  const [menugroup, setMenugroup]             = useState([]);
  const [contents, setContents]               = useState([]);
  const [subComponent, setSubComponent]       = useState(false);
  //const [view, setView]                       = useState(false);

  init(inputs, setInputs)

  useEffect(() => {
    if (inputs.components!=='') {
      setSubComponent(false)
      switch (inputs.components) {
        case "Grupo de Contenido":
          setSubComponent(<Select data={categories}
                                  name="component_id"
                                  required={true}
                                  defaultValue={inputs.component_id}
                                  placeholder='Categoría'
                                  inputs={inputs}
                                  setInputs={set}
                                  label="Categorías"
                                  value={inputs.component_id} />)
        break;
        case "cms":
          console.log(contents)
          setSubComponent(<Select data={contents}
                                  name="component_id"
                                  required={true}
                                  defaultValue={inputs.component_id}
                                  placeholder='Contenido'
                                  inputs={inputs}
                                  setInputs={set}
                                  label="Contenido"
                                  value={inputs.component_id} />)
        break;
        case "Formularios":
          setSubComponent(<Select data={[{name:"FormBasic",id:"FormBasic"}]}
                                  name="component_id"
                                  required={true}
                                  defaultValue={inputs.component_id}
                                  placeholder='Formulario'
                                  inputs={inputs}
                                  setInputs={set}
                                  label="Formulario"
                                  value={inputs.component_id} />)
        break;
        default:
        setSubComponent(false)
      }
    }
  },[inputs,categories,contents]);

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{

    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data && response.data.content) {
        setInputs(response.data.content)
      }
      if (response.data && response.data.menu) {
        setInputs(response.data.menu)
      }
      if (response.data && response.data.contents) {
        setContents(response.data.contents)
      }
      if (response.data && response.data.categories) {
        setCategories(response.data.categories)
      }
      if (response.data && response.data.components) {
        setComponents(response.data.components)
      }
      if (response.data && response.data.menugroup) {
        setMenugroup(response.data.menugroup)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()

    context.post(false,{  ...inputs},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        navigate('/menu/add/'+response.data.id)
      }
    })
  }



  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={11} sm={11} md={11} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Paper sx={{ p: 2, }}>
                        <Grid container spacing={0}>
                          <Grid  item xs={12} sx={{ ml: 2, mb: 2 }}>
                            <Typography variant="h6" >
                              <b>Datos básicos</b>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid  item xs={12} md={2}>
                            <Select data={components}
                                    name="components"
                                    required={true}
                                    defaultValue={inputs.components}
                                    placeholder='Componente'
                                    inputs={inputs}
                                    setInputs={set}
                                    label="Componente"
                                    value={inputs.components} />
                          </Grid>

                          {subComponent?<Grid  item xs={12} md={2}>
                            {subComponent}
                          </Grid>:false}

                          {subComponent?<>
                            <Grid  item xs={12} md={2}>
                              <Select data={menugroup}
                                      name="menugroup"
                                      required={true}
                                      defaultValue={inputs.menugroup}
                                      placeholder='Menú Grupo'
                                      inputs={inputs}
                                      setInputs={set}
                                      label="Menú Grupo"
                                      value={inputs.menugroup} />
                            </Grid>
                            <Grid  item xs={12} md={4}>
                              <Input  margin="normal"
                                      required
                                      fullWidth
                                      type="text"
                                      defaultValue={inputs.name}
                                      label="Título"
                                      name="name"
                                      onChange={set}
                                      />
                            </Grid>
                            <Grid  item xs={12} md={2}>
                              <Select data={[{id:0,name:"No Publicado"},{id:1,name:"Publicado"}]}
                                      name="status"
                                      required={true}
                                      defaultValue={inputs.status}
                                      placeholder='Estado'
                                      inputs={inputs}
                                      setInputs={set}
                                      label="Estado"
                                      value={inputs.status} />
                            </Grid>
                          </>:false
                          }

                          <Grid  item xs={12}>
                            <Button fullWidth
                                    variant="contained"
                                    type="submit"
                                    sx={{ mt: 3, mb: 2 }}>Guardar</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
