import Grid from '@mui/material/Grid';
import Table from '../common/Table';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { NavLink } from "react-router-dom";

const td  = [
              {
                label:"Item",
                value:"name",
                align:"left"
              },
              {
                label:"Imagen",
                value:"url",
                align:"left"
              },
              {
                label:"Adjunto",
                value:"href",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["del"],
                event:true
              },
            ]

const App = ({usePrivileges})=>{
  return  <Grid container
                component="main"
                direction="row"
                className="min-height-80"
                justifyContent="center"
                >
            <Grid item xs={11}>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
                  <Table td={td} add={<NavLink to="/banners/add/0"><AddCircleOutlineIcon  className="ml-2 cursor-pointer"/></NavLink>}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
