import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loading    from '../../../../components/common/Loading03';
import Typography from '@mui/material/Typography';
import Input    from '../../../../components/common/Input';
import InputAdornment from '@mui/material/InputAdornment';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PercentIcon from '@mui/icons-material/Percent';
import Select from '../../../../components/common/Select';
import DatePicker from '../../../../components/common/DatePicker';
import SchoolIcon from '@mui/icons-material/School';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorefrontIcon from '@mui/icons-material/Storefront';

//import Step42BasicForm from './Step42BasicForm';

import StateContext from '../../../../helpers/ContextState';
import { useNavigate } from "react-router-dom";
import BtnSubmit from '../../../../components/common/BtnSubmit';
const btnSubmitId    =   'btnSubmitId4'

const App=(props)=>{

  const navigate = useNavigate();
  const context  = React.useContext(StateContext);
  const [inputs, setInputs]     = React.useState({});
  const [loading, setLoading]   = React.useState(false);


  const [discount, setDiscount] = React.useState(true);


  React.useEffect(()=>{
    if (props.inputs && props.inputs.id && !inputs.id) {
      setLoading(true)
      setInputs(props.inputs)
      setTimeout(()=>{
        setLoading(false)
      },2000)
    }
  },[props,inputs])

  React.useEffect(()=>{
    if (props.submit) {
      document.getElementById(btnSubmitId).click();
      props.setSubmit(false)
    }
  },[props])

  const onChange=(e)=>{

    if (  e.target.name==="discount_amount" &&
          e.target.value==="Más del 10%") {
          setDiscount(false)
    }else if (  e.target.name==="discount_amount" &&
          e.target.value!=="Más del 10%") {
          setDiscount(true)
    }

    inputs[e.target.name]  = e.target.value
    //console.log(inputs[e.target.name]);
    // let inputs_ = {...inputs}
    //     inputs_[e.target.name]=e.target.value
    //setInputs(inputs)
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    inputs.step = props.step;
    context.post(false,{...inputs},false,true).then((response)=>{
      if (response && response.data) {
        props.submitResponse(response.data)
        setInputs(response.data)
        if (response.data.token) {
          navigate('/web/formulario/afiliate/'+response.data.token)
        }else if (response.data.id) {

        }
      }
    })
  }

  return  <Box>
            <form onSubmit={onSubmit} id={"form4"}>
              <BtnSubmit id={btnSubmitId}/>
              <Grid container spacing={2} className="card-">
                <Grid item xs={12} className="h1 text-center2 p-2">
                  Información financiera
                </Grid>
                {loading?<Loading/>:<>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Nivel de Escolaridad"
                            name="education_level"
                            defaultValue={inputs.education_level}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <SchoolIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DatePicker margin="normal"
                                size="small"
                                required={true}
                                inputs={props.inputs}
                                label="Fecha de ingreso a la empresa"
                                defaultValue={inputs.date_into_employment}
                                name="date_into_employment"
                                onChange={onChange}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Empresa"
                            name="business"
                            defaultValue={inputs.business}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <BusinessIcon />
                                              </InputAdornment>
                                            ),
                                        }}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            size="small"
                            label="Cargo"
                            name="position"
                            defaultValue={inputs.position}
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <ManageAccountsIcon />
                                              </InputAdornment>
                                            ),
                                        }}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            size="small"
                            label="Salario"
                            name="salary"
                            defaultValue={inputs.salary}
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AttachMoneyIcon />
                                              </InputAdornment>
                                            ),
                                        }}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Negocio/Dependencia"
                            name="business_dependence"
                            defaultValue={inputs.business_dependence}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <BusinessCenterIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Select data={[
                                    {
                                      name:"7%",
                                      value:"7",
                                    },
                                    {
                                      name:"8%",
                                      value:"8",
                                    },
                                    {
                                      name:"9%",
                                      value:"9",
                                    },
                                    {
                                      name:"10%",
                                      value:"10",
                                    },
                                    {
                                      name:"Más del 10%",
                                      value:"Other",
                                    },
                                  ]}
                            size="small"
                            name="discount_amount"
                            required={true}
                            defaultValue={inputs.discount_amount}
                            placeholder='Autorizo a descontar de mi salario'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Autorizo a descontar de mi salario"
                      />
                  </Grid>
                  {!discount?<Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            disabled={discount}
                            type="number"
                            min={10}
                            max={100}
                            label="Cuál porcentaje de descuento autorizas"
                            name="discount_amount_extra"
                            defaultValue={inputs.discount_amount_extra}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <PercentIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>:false}
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>¿Desea abrir una cuenta de ahorro adicional voluntaria desde $10.000 pesos quincenales?</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Select data={[
                                    {
                                      name:"Ahorro voluntario",
                                      value:"Ahorro voluntario",
                                    },
                                    {
                                      name:"Ahorro FESOL",
                                      value:"Ahorro FESOL",
                                    },
                                    {
                                      name:"Ahorro Programado Para Vivienda",
                                      value:"Ahorro Programado Para Vivienda",
                                    },
                                    {
                                      name:"Ahorro programado para vacaciones",
                                      value:"Ahorro programado para vacaciones",
                                    },
                                  ]}
                            size="small"
                            name="savings_program"
                            defaultValue={inputs.savings_program}
                            placeholder='Programa de ahorros'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Programa de ahorros"
                      />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"no",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="open_account"
                            defaultValue={inputs.open_account}
                            placeholder='Abrir cuenta de ahorros'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Abrir cuenta de ahorros"
                      />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Input  margin="normal"
                            type="number"
                            label="Monto"
                            min="100000"
                            name="open_account_amount"
                            defaultValue={inputs.open_account_amount}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AttachMoneyIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>

                  <Grid item xs={12} >
                    <Grid  sx={{pl:1}}>
                      <Grid className="border-separated">

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{pl:2}}>
                      <b>Intereses</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}  >
                    <FormControlLabel control={<Checkbox name="interest_cinema" value="Cine" />} label="Cine" />
                  </Grid>
                  <Grid item xs={12} md={2} >
                    <FormControlLabel control={<Checkbox name="interest_theater" value="Teatro" />} label="Teatro" />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <FormControlLabel control={<Checkbox name="interest_concerts_shows" value="Conciertos y Espectáculos" />} label="Conciertos y Espectáculos" />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <FormControlLabel control={<Checkbox name="interest_subscriptions"  value="Suscripciones"/>} label="Suscripciones" />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <FormControlLabel control={<Checkbox name="interest_gym" value="Gimnasios"/>} label="Gimnasios" />
                  </Grid>
                  <Grid item xs={12} >
                    <Grid  sx={{pl:1}}>
                      <Grid className="border-separated">

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Tiene vivienda propia</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            required={true}
                            size="small"
                            name="own_home"
                            defaultValue={inputs.own_home}
                            placeholder='Vivienda propia'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Vivienda propia"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Es financiada</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="financed_home"
                            defaultValue={inputs.financed_home}
                            placeholder='Financiada'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Financiada"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Entidad</b>
                    </Typography>
                    <Input  margin="normal"
                            type="text"
                            label="Entidad"
                            name="financed_home_bank"
                            defaultValue={inputs.financed_home_bank}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountBalanceIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>



                  <Grid item xs={12} >
                    <Grid  sx={{pl:1}}>
                      <Grid className="border-separated">

                      </Grid>
                    </Grid>
                  </Grid>


                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Tiene vehiculo propio</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="own_vehicle"
                            defaultValue={inputs.own_vehicle}
                            placeholder='Vehículo propio'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Vehículo propio"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Es financiado</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="financed_vehicle"
                            defaultValue={inputs.financed_vehicle}
                            placeholder='Financiado'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Financiado"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Entidad</b>
                    </Typography>
                    <Input  margin="normal"
                            type="text"
                            label="Entidad"
                            name="financed_vehicle_bank"
                            defaultValue={inputs.financed_vehicle_bank}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountBalanceIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>




                  <Grid item xs={12} >
                    <Grid  sx={{pl:1}}>
                      <Grid className="border-separated">

                      </Grid>
                    </Grid>
                  </Grid>



                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Tiene seguros de vida</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="life_insurance"
                            defaultValue={inputs.life_insurance}
                            placeholder='Seguro de vida'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Seguro de vida"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Entidad</b>
                    </Typography>
                    <Input  margin="normal"
                            type="text"
                            label="Entidad"
                            name="life_insurance_bank"
                            defaultValue={inputs.life_insurance_bank}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountBalanceIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>

                  <Grid item xs={12} md={4} >

                  </Grid>

                  <Grid item xs={12} >
                    <Grid  sx={{pl:1}}>
                      <Grid className="border-separated">

                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Desea tomar seguro exequial</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="funeral_insurance"
                            defaultValue={inputs.funeral_insurance}
                            placeholder='Seguro exequial'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Seguro exequial"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>¿Tiene créditos con entidades del sector financiero?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            required={true}
                            name="have_credit"
                            defaultValue={inputs.have_credit}
                            placeholder='Tiene créditos'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Tiene créditos"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Si la respuesta es si en que rango (Millones)</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Entre 0 y 5",
                                      value:"0-5",
                                    },
                                    {
                                      name:"Entre 5 y 10",
                                      value:"5-10",
                                    },
                                    {
                                      name:"Entre 10 y 20",
                                      value:"10-20",
                                    },
                                    {
                                      name:"Más de 20",
                                      value:"20",
                                    },
                                  ]}
                            size="small"
                            name="have_credit_amount"
                            defaultValue={inputs.have_credit_amount}
                            placeholder='Rango'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Rango"
                      />
                  </Grid>



                  <Grid item xs={12} >
                    <Grid  sx={{pl:1}}>
                      <Grid className="border-separated">

                      </Grid>
                    </Grid>
                  </Grid>



                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Tiene algún tipo de negocio propio</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="own_business"
                            defaultValue={inputs.own_business}
                            placeholder='Negocio propio'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Negocio propio"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>Le gustaría tenerlo</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="would_you_like_business"
                            defaultValue={inputs.would_you_like_business}
                            placeholder='Le gustaría'
                            inputs={props.inputs}
                            setInputs={onChange}
                            label="Le gustaría"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>¿Cual es su idea de negocio?</b>
                    </Typography>
                    <Input  margin="normal"
                            type="text"
                            label="Idea de negocio"
                            name="business_idea"
                            defaultValue={inputs.business_idea}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>¿Manejó recursos públicos?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="recursos_publicos"
                            defaultValue={inputs.recursos_publicos}
                            placeholder='¿Manejó recursos públicos?'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Por su cargo o actividad"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>¿Ejerció algún grado de poder público?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="poder_publico"
                            defaultValue={inputs.poder_publico}
                            placeholder='¿Ejerció algún grado de poder público?'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Por su cargo o actividad"
                      />
                  </Grid>
                  <Grid item xs={12} md={4} >
                    <Typography sx={{pl:2}}>
                      <b>¿Gozó usted de reconocimiento público general?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="reconocimiento_publico"
                            defaultValue={inputs.reconocimiento_publico}
                            placeholder='¿Gozó usted de reconocimiento público general?'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Por su cargo u oficio"
                      />
                  </Grid>
                  <Grid item xs={12} >
                    <Typography sx={{pl:2}}>
                      <b>¿Tiene Familiares hasta el segundo grado de consanguinidad y afinidad que encajen en los escenarios descritos previamente?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            name="familiar_reconocimiento_publico"
                            defaultValue={inputs.familiar_reconocimiento_publico}
                            placeholder='¿que encajen en los escenarios descritos previamente?'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Tiene Familiares hasta el segundo grado de consanguinidad y afinidad"
                      />
                  </Grid>
                  <Grid item xs={12} >
                    <Typography sx={{pl:2}}>
                      <b>Si alguna de las preguntas anteriores es afirmativa por favor especifique:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Ingreso mensual"
                            required={true}
                            name="ingreso_mensual"
                            defaultValue={inputs.ingreso_mensual}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Egreos"
                            required={true}
                            name="egresos"
                            defaultValue={inputs.egresos}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Activos"
                            name="activos"
                            required={true}
                            defaultValue={inputs.activos}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Pasivos"
                            name="pasivos"
                            required={true}
                            defaultValue={inputs.pasivos}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Otros Ingresos"
                            name="otros_ingresos"
                            required={true}
                            defaultValue={inputs.otros_ingresos}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Concepto otros ingresos"
                            name="concepto_otros_ingreos"
                            required={true}
                            defaultValue={inputs.concepto_otros_ingreos}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography sx={{pl:2}}>
                      <b>¿Realiza transacciones en moneda extranjera?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Si",
                                      value:"Si",
                                    },
                                    {
                                      name:"No",
                                      value:"No",
                                    },
                                  ]}
                            size="small"
                            required={true}
                            name="moneda_extrajera"
                            defaultValue={inputs.moneda_extrajera}
                            placeholder='¿en moneda extranjera?'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Realiza transacciones"
                      />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography sx={{pl:2}}>
                      <b>¿Concepto?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Importaciones",
                                      value:"Importaciones",
                                    },
                                    {
                                      name:"Exportaciones",
                                      value:"Exportaciones",
                                    },
                                    {
                                      name:"Inversiones",
                                      value:"Inversiones",
                                    },
                                    {
                                      name:"Transferencias",
                                      value:"Transferencias",
                                    },
                                  ]}
                            size="small"
                            name="moneda_extrajera_concepto"
                            defaultValue={inputs.moneda_extrajera_concepto}
                            placeholder='¿Concepto?'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Concepto"
                      />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography sx={{pl:2}}>
                      <b>¿Concepto?</b>
                    </Typography>
                    <Select data={[
                                    {
                                      name:"Productos financieros en el Exterior",
                                      value:"Productos financieros en el Exterior",
                                    },
                                    {
                                      name:"Pago de servicios",
                                      value:"Pago de servicios",
                                    },
                                    {
                                      name:"Otros",
                                      value:"Otros",
                                    },
                                  ]}
                            size="small"
                            name="moneda_extrajera_concepto"
                            defaultValue={inputs.moneda_extrajera_concepto2}
                            placeholder='¿Concepto?'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Concepto"
                      />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Tipo de Producto"
                            name="tipo_producto"
                            defaultValue={inputs.tipo_producto}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Identificación o número del Producto"
                            name="tipo_producto_identificacion"
                            defaultValue={inputs.tipo_producto_identificacion}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Entidad"
                            name="entidad"
                            defaultValue={inputs.entidad}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Monto"
                            name="monto"
                            defaultValue={inputs.monto}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Ciudad"
                            name="ciudad"
                            defaultValue={inputs.ciudad}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="País"
                            name="pais"
                            defaultValue={inputs.pais}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <Input  margin="normal"
                            type="text"
                            label="Moneda"
                            name="moneda"
                            defaultValue={inputs.moneda}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <StorefrontIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
















                </>}
              </Grid>
            </form>
          </Box>
}

export default App
