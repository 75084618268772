import React from 'react';
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import Loading from '../common/Loading01';
import Gallery from '../common/Gallery';

let getInit=false

const App=({context,event,filter})=>{

  const [loading, setLoading] = React.useState(true);
  const [data, setData]       = React.useState([]);
  const [value, setValue]     = React.useState(false);

  React.useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=()=>{
    context.get('api/multimedia?q='+filter,{},false,false).then((response)=>{
      setLoading(false)
      if (response && response.data) {
        setData(response.data)
      }
    })
  }

  const onClick=(row)=>{
    setValue(row)
    navigator.clipboard.writeText(row.url).then(() => {
        if (event) {
          event(row)
        }
        //console.log("Text copied to clipboard...")
    }).catch(err => {
        console.log('Something went wrong', err);
    })
    //copyToClipBoard()
  }




  return  <>{
              loading?<Loading height={'60vh'}/>:<Grid container justifyContent="center">
                    <Grid  item xs={11}>
                      <Grid container>
                        <Grid  item xs={12} md={12} sx={{mt:2}}>
                          {value.url}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid  item xs={12} md={12} >
                          <Gallery data={data} onClick={onClick}/>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
            }
          </>

}
export default App;
