import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loading    from '../../../../components/common/Loading03';
import Input    from '../../../../components/common/Input';
import Select from '../../../../components/common/Select';
import BtnSubmit from '../../../../components/common/BtnSubmit';
import DatePicker from '../../../../components/common/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StateContext from '../../../../helpers/ContextState';
import { useNavigate } from "react-router-dom";

const btnSubmitId    =   'btnSubmitId'

const App=({submitResponse,activeStep,step,submit,setSubmit,setInputs,inputs})=>{
  const navigate = useNavigate();
  const context  = React.useContext(StateContext);
  const [loading, setLoading]   = React.useState(false);

  React.useEffect(()=>{
    if (submit) {
      document.getElementById(btnSubmitId).click();
      setSubmit(false)
    }
  },[submit,setSubmit])

  const onChange=(e)=>{
      inputs[e.target.name]  = e.target.value
  }



  const onSubmit=(e)=>{
    e.preventDefault()
    inputs.step = step;

    context.post(false,{...inputs},false,true).then((response)=>{
      if (response && response.data) {
        if (response.data && response.data==='close') {
          return navigate('/web/formulario/afiliate_exist')
        }

        submitResponse(response.data)
        setInputs(response.data)

        if (response.data.token) {
          navigate('/web/formulario/afiliate/'+response.data.token)
        }else if (response.data.id) {

        }
      }
    })
  }

  return  <Box>
            <form onSubmit={onSubmit} id={"form1"}>
              <BtnSubmit id={btnSubmitId}/>
              <Grid container spacing={2} className="card-">
                {loading?<Loading setLoading={setLoading}/>:<>
                  <Grid item xs={12} className="h1 text-center2 p-2">
                    Datos Personales
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Nombres"
                            name="name"
                            defaultValue={(inputs && inputs.name)?inputs.name:""}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountCircle />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Apellidos"
                            name="surnames"
                            defaultValue={(inputs && inputs.surnames)?inputs.surnames:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountCircle />
                                              </InputAdornment>
                                            ),
                                        }}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Select data={[
                                    {
                                      name:"Masculino",
                                      value:"Masculino",
                                    },
                                    {
                                      name:"Femenino",
                                      value:"Femenino",
                                    },
                                  ]}
                            size="small"
                            name="gender"
                            defaultValue={(inputs && inputs.gender)?inputs.gender:""}
                            required={true}
                            placeholder='Género'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Género"
                      />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Select data={[
                                    {
                                      name:"Soltero(a)",
                                      value:"Soltero(a)",
                                    },
                                    {
                                      name:"Casado(a)",
                                      value:"Casado(a)",
                                    },
                                    {
                                      name:"Separado(a)",
                                      value:"Separado(a)",
                                    },
                                    {
                                      name:"Viudo(a)",
                                      value:"Viudo(a)",
                                    },
                                    {
                                      name:"Unión Libre",
                                      value:"Unión Libre",
                                    },
                                  ]}
                            size="small"
                            name="marital_status"
                            defaultValue={(inputs && inputs.marital_status)?inputs.marital_status:""}
                            required={true}
                            placeholder='Estado civil'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Estado civil"
                      />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Select data={[
                                    {
                                      name:"Cédula de Ciudadanía",
                                      value:"CC",
                                    },
                                    {
                                      name:"Cédula de Extranjería",
                                      value:"CE",
                                    },
                                  ]}
                            size="small"
                            name="document_type"
                            defaultValue={(inputs && inputs.document_type)?inputs.document_type:""}
                            required={true}
                            placeholder='Tipo de documento'
                            inputs={inputs}
                            setInputs={onChange}
                            label="Tipo de documento"
                      />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            size="small"
                            required={true}
                            label="Nro. Identificación"
                            name="identification"
                            defaultValue={(inputs && inputs.identification)?inputs.identification:""}
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AssignmentIndIcon />
                                              </InputAdornment>
                                            ),
                                        }}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            size="small"
                            required={true}
                            label="Lugar de expedición"
                            name="identification_expedition_place"
                            defaultValue={(inputs && inputs.identification_expedition_place)?inputs.identification_expedition_place:""}
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AddLocationAltIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DatePicker margin="normal"
                                size="small"
                                inputs={inputs}
                                label="Fecha de expedición"
                                name="identification_expedition_date"
                                defaultValue={(inputs && inputs.identification_expedition_date)?inputs.identification_expedition_date:""}
                                onChange={onChange}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Nacionalidad"
                            name="nationality"
                            defaultValue={(inputs && inputs.nationality)?inputs.nationality:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <TravelExploreIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Lugar de nacimiento"
                            name="place_birth"
                            defaultValue={(inputs && inputs.place_birth)?inputs.place_birth:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AddLocationAltIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <DatePicker margin="normal"
                                inputs={inputs}
                                required={true}
                                label="Fecha de nacimiento"
                                name="date_birth"
                                defaultValue={(inputs && inputs.date_birth)?inputs.date_birth:""}
                                size="small"
                                onChange={setInputs}
                                InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <AddLocationAltIcon />
                                                  </InputAdornment>
                                                ),
                                            }}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="number"
                            required={true}
                            label="Persona a cargo"
                            name="dependents"
                            defaultValue={(inputs && inputs.dependents)?inputs.dependents:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountTreeIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Dirección"
                            name="direction"
                            defaultValue={(inputs && inputs.direction)?inputs.direction:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AssistantDirectionIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Barrio"
                            name="neighborhood"
                            defaultValue={(inputs && inputs.neighborhood)?inputs.neighborhood:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AddBusinessIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Ciudad"
                            name="city"
                            defaultValue={(inputs && inputs.city)?inputs.city:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <LocationCityIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Departamento"
                            name="department"
                            defaultValue={(inputs && inputs.department)?inputs.department:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <PublicIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            label="Telefono Fijo "
                            name="phone"
                            defaultValue={(inputs && inputs.phone)?inputs.phone:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AssistantDirectionIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Celular"
                            name="cellphone"
                            defaultValue={(inputs && inputs.cellphone)?inputs.cellphone:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <PhoneIphoneIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            required={true}
                            label="Estrato"
                            name="stratum"
                            defaultValue={(inputs && inputs.stratum)?inputs.stratum:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <LocationCityIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="email"
                            required={true}
                            label="E-mail"
                            name="email"
                            defaultValue={(inputs && inputs.email)?inputs.email:""}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <PublicIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                    />
                  </Grid>
                </>}
              </Grid>
            </form>
          </Box>
}

export default App
