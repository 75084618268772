import Grid from '@mui/material/Grid';
import Steps from '../common/Steps';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const App=()=>{
  return  <Grid container
                component="main"
                direction="row"
                className="min-height-80"
                justifyContent="center"
                >

            <Grid item xs={11}>
              <Paper sx={{ p: 2, mb:3, mt:6}}>
                <Typography>
                  Para afiliarte debes diligenciar este sencillo formulario de manera digital,
                  una vez terminado el proceso en los próximos días un asesor se contactará contigo y
                  te hará saber si ya quedaste afiliado. *Recuerda que es obligatorio adjuntar la
                  fotocopia de la Cédula de ciudadanía por ambos lados.
                </Typography>
              </Paper>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{ mt: 1,mb: 0, p:0 }}>
                  <Steps/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App;
