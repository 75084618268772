import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
const style1 = {
  position: 'relative',
};
const style2 = {
  position:'absolute',
  width: '100%',
};
const Loading=()=>{
  return  <Grid container sx={style1}>
            <Box sx={style2}>
              <LinearProgress color="secondary" />
            </Box>
          </Grid>
}
export default Loading
