import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper    from '@mui/material/Paper';
import StateContext from '../../helpers/ContextState';
import Input    from '../common/Input';
import Switch   from '../common/Switch';
import BtnSubmit from '../common/BtnSubmit';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
          init,
          set
        } from '../../helpers/fninputs';

let getInit
const btnSubmitId    =   'btnSubmitId'

const App=()=>{
  const navigate = useNavigate();
  const location = useLocation();
  const context  = React.useContext(StateContext);
  const [inputs, setInputs]       = useState({});
  const [data, setData] = useState({});

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        //setInputs(response.data)
      }
      if (response.data && response.data.basicRoleActions) {
        let inputs_ = {...inputs}

        response.data.basicRoleActions.map((row,key)=>{
          if (response.data.roles.find((search)=>search.permission_id===row.id)) {
            if (!inputs_.multipleInputs) {
              return inputs_.multipleInputs  =  {}
            }
            return inputs_.multipleInputs[row.name]    =   true
          }
          return false
        })
        if (inputs_) {
          setInputs({...response.data,...inputs_});
        }
        setData(response.data)
      }
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);

  const onSubmit=(e)=>{
    e.preventDefault()

    context.post('api/setting/createPermission',{...inputs,multipleInputs:JSON.stringify(inputs.multipleInputs)},false,true).then((response)=>{
      if (response && response.data && response.data.id) {
        navigate('/setting/editPermissionEdit/'+response.data.id)
      }
    })
  }


  return  <Grid sx={{ flexGrow: 1 }} container spacing={0}>
            {Object.entries(inputs).length >0 || location.pathname.includes("/0")?<>
              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={11} sm={11} md={3} >
                    <form onSubmit={onSubmit}>
                      <Paper sx={{ p: 2, }} >
                        <Grid style={{display:'flex',justifyContent:'center'}}>
                          <Typography variant="h6" >
                            <b>{inputs.name}</b>
                          </Typography>
                        </Grid>
                        <Grid style={{display:'flex',justifyContent:'center'}}>
                          <Input  margin="normal"
                                  required={true}
                                  fullWidth
                                  type="text"
                                  id="name"
                                  label="Nombre del permiso"
                                  name="name"
                                  autoFocus
                                  defaultValue={inputs.name}
                                  onChange={set}/>
                        </Grid>
                      </Paper>
                      <BtnSubmit id={btnSubmitId}/>
                    </form>
                  </Grid>
                  <Grid  item xs={11} sm={11} md={8} >
                      <Paper sx={{ p: 2, }}>
                      {data && data.basicRoleActions?<Grid container>
                        {data.basicRoleActions.map((row,key)=>{
                          return  <Grid item xs={4} key={key}>
                                    <Switch data={data.roles} name={row.name} inputs={inputs} setInputs={setInputs} label={row.name} row={row} />
                                  </Grid>
                        })}
                      </Grid>:false}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </>:false}
          </Grid>
}
export default App
