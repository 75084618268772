import Grid from '@mui/material/Grid';
import Table from '../common/Table';


const td  = [
              {
                label:"Nombre y apellido",
                value:"name",
                align:"left"
              },
              {
                label:"Celular",
                value:"cellphone",
                align:"left"
              },
              {
                label:"Email",
                value:"email",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["view","edit"]
              },
            ]

const App = ({usePrivileges})=>{
  return  <Grid container
                component="main"
                direction="row"
                className="min-height-80"
                justifyContent="center"
                >
            <Grid item xs={11}>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
                  <Table td={td}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
