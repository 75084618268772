import * as React from 'react';
import Grid from '@mui/material/Grid';
import Table from '../common/Table';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { NavLink } from "react-router-dom";

const td  = [
              {
                label:"Estatus",
                value:"status",
                align:"left"
              },
              {
                label:"Categoría",
                value:"category",
                align:"left"
              },
              {
                label:"Título",
                value:"title",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["view","activity"]
              },
            ]

const App = ({usePrivileges,endpoint})=>{
  const [reload, setReload]   = React.useState(false);

  const handleOnClick=()=>{

  }

  return  <Grid container
                component="main"
                direction="row"
                className="min-height-80"
                justifyContent="center"
                >

            <Grid item xs={11}>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
                  <Table td={td} add={<NavLink to="/estructura_organizacional/add/0"><AddCircleOutlineIcon  className="ml-2 cursor-pointer"/></NavLink>}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
