import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Paper    from '@mui/material/Paper';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import StateContext from '../../helpers/ContextState';
import Select from '../common/Select';
import Loading from '../common/Loading01';
import Button from '@mui/material/Button';
import {init,set} from '../../helpers/fninputs';

import { useNavigate } from "react-router-dom";
import Textarea from '../common/TextareaCustom';
import ContentGallery from '../multimedia/ContentGallery';
import Carousel    from '../../web/components/Carousel';

const btnSubmitId    =   'btnSubmitId'
let getInit

const App=()=>{
  const navigate                              = useNavigate();
  const context                               = React.useContext(StateContext);
  const [loading, setLoading]                 = useState(true);
  const [inputs, setInputs]                   = useState({});
  const [categories, setCategories]           = useState({});
  const [gallery, setGallery]                 = useState([]);
  const [slider, setSlider]                   = useState([]);

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{

    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data && response.data.content) {
        setInputs(response.data.content)
      }
      if (response.data && response.data.content &&  response.data.content.gallery) {
        setGallery(JSON.parse(response.data.content.gallery))
      }
      if (response.data && response.data.categories) {
        setCategories(response.data.categories)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()

    context.post(false,{  ...inputs,gallery:JSON.stringify(gallery),slider:JSON.stringify(slider)},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        navigate('/contents/add/'+response.data.id)
      }
    })
  }

  const handleGallery=(event,filter)=>{
    context.setModalShow({
                            show:true,
                            message:  <Grid sx={{height:"80vh"}}>
                                        <ContentGallery event={event} context={context} filter={filter}/>
                                      </Grid>,
                            width:"90%"
                          }
                        )
  }

  const eventOne=(row)=>{
    gallery.push(row)
    setGallery(gallery)
  }

  const eventTwo=(row)=>{
    slider.push(row)
    setSlider(slider)
  }

  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={11} sm={11} md={11} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Paper sx={{ p: 2, }}>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid  item xs={12} sm={6} >
                            <Button fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{handleGallery(eventOne,"banner")}}
                                    >Banner</Button>
                          </Grid>
                          <Grid  item xs={12} sm={6} >
                            <Button fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={()=>{handleGallery(eventTwo,"sliders")}}
                                    >Sliders</Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid  item xs={12} sm={6} >
                            <Select data={[{id:0,name:"No Mostrar Video"},{id:1,name:"Mostrar Video"}]}
                                    name="active_video"
                                    required={true}
                                    defaultValue={inputs.active_video}
                                    placeholder='Mostrar videos'
                                    inputs={inputs}
                                    setInputs={set}
                                    label="Mostrar videos"
                                    value={inputs.active_video} />
                          </Grid>
                          <Grid  item xs={12} sm={6} >
                            <Select data={[{id:0,name:"No Mostrar Noticias"},{id:1,name:"Mostrar Noticias"}]}
                                    name="active_news"
                                    required={true}
                                    defaultValue={inputs.active_news}
                                    placeholder='Mostrar Noticias'
                                    inputs={inputs}
                                    setInputs={set}
                                    label="Mostrar Noticias"
                                    value={inputs.active_news} />
                          </Grid>
                        </Grid>
                        <Carousel data={gallery}/>
                        <Grid container spacing={0}>
                          <Grid  item xs={12} md={2}>
                            <Select data={[{id:0,name:"No Publicado"},{id:1,name:"Publicado"}]}
                                    name="status"
                                    required={true}
                                    defaultValue={inputs.status}
                                    placeholder='Estado del contenido'
                                    inputs={inputs}
                                    setInputs={set}
                                    label="Estado del contenido"
                                    value={inputs.status} />
                          </Grid>
                          <Grid  item xs={12} md={2}>
                            <Select data={categories}
                                    name="category"
                                    required={true}
                                    defaultValue={inputs.category}
                                    placeholder='Categoría'
                                    inputs={inputs}
                                    setInputs={set}
                                    label="Categoría"
                                    value={inputs.category} />
                          </Grid>
                          <Grid  item xs={12} md={8}>
                            <Input  margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    defaultValue={inputs.title}
                                    label="Título"
                                    name="title"
                                    onChange={set}
                                    />
                          </Grid>
                          <Grid  item xs={12}>
                            <Textarea name="content" inputs={inputs} setInputs={setInputs}/>
                          </Grid>
                          <Grid  item xs={12}>
                            <Button fullWidth
                                    variant="contained"
                                    type="submit"
                                    sx={{ mt: 3, mb: 2 }}>Guardar</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
