import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#ff0000',
    },
  },
});
