import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import pdf_img from '../../assets/images/pdf.jpg';
import IconButton from '@mui/material/IconButton';
import Camera from './Camera';
import StateContext from '../../helpers/ContextState';
import {open} from '../../helpers/fninputs';



export default function RecipeReviewCard(props) {
  const context       =   React.useContext(StateContext);
  const [img,setImg]  =   React.useState(false);
  const [pdf,setPDF]  =   React.useState(false);
  React.useEffect(()=>{
    if (props.inputs && props.inputs[props.name] && (props.inputs[props.name]!=='null'||props.inputs[props.name]!==null)) {
      if (props.inputs[props.name].includes("pdf")) {
        setPDF(props.inputs[props.name])
      }else if (props.inputs[props.name].includes("png")) {
        setImg(props.inputs[props.name])
      }else if (props.inputs[props.name].includes("gif")) {
        setImg(props.inputs[props.name])
      }else if (props.inputs[props.name].includes("jpg")) {
        setImg(props.inputs[props.name])
      }else if (props.inputs[props.name].includes("jpeg")) {
        setImg(props.inputs[props.name])
      }
    }
  },[props]);
  return (
    <Card sx={{ maxWidth: "100%" }} className="border-bottom-red">
      <CardHeader
        avatar={
          <IconButton aria-label="Subir documento" className={"cursor-pointer cam-content"}>
            {props.id?<Camera url={"api/web/formulario/afiliate_documents"}
                              name={props.name} id={props.id}
                              context={context}
                              setImg={setImg}
                              setPDF={setPDF}
                              />:false}
          </IconButton>
        }
        title={props.label}
        subheader={props.sublabel}
      />
      {img?<img src={img} alt="Programandoweb" className="img-fluid"/>:false}
      {pdf? <Grid container alignContent={'center'} xs={12}>
              <Grid container item xs={12} justifyContent={'center'}>
                <img onClick={()=>open(pdf)} src={pdf_img} alt="Programandoweb" className="img-fluid cursor-pointer"/>
              </Grid>
            </Grid>:false}
    </Card>
  );
}
