import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import StateContext from '../../helpers/ContextState';

export default function SelectLabels({data,name,label,inputs,setInputs,placeholder,required,defaultValue,byId,children,disabled,size}) {

  const context           =   React.useContext(StateContext);
  const [age, setAge]     =   React.useState(defaultValue);
  const [error, setError] =   React.useState(required);

  const [reset, setReset]       = useState(false)

  const handleChange = (event) => {
    if (event.target.value) {
      setError(false)
    }else {
      setError(true)
    }
    setInputs(event)
    setAge(event.target.value);
  };

  useEffect(()=>{
    setAge(defaultValue)
  },[defaultValue])

  useEffect(()=>{
    if (reset) {
      setReset(false)
    }
  },[reset])

  const onChange=(e)=>{
    if (children) {
      context.post("api/"+children.url,{id:e.target.value},false,false).then((response)=>{
        if (response.data) {
          let inputs_         =   {...children.input}
              inputs_         =   response.data
              children.setInput(inputs_)
        }
      })
    }
  }

  return (  <>
              {data && data.length && !reset>0?<>
                <FormControl sx={{ minWidth: "100%" }} error={error||false} disabled={disabled} size={size} required={required}>
                  <InputLabel id="demo-simple-select-helper-label" sx={{m:1}}>
                    {label}
                  </InputLabel>
                  <Select
                    sx={{m:1}}

                    name={name}
                    labelId="demo-simple-select-helper-label"
                    id={name}
                    autoWidth={true}
                    value={age?age:""}
                    label={label}
                    onChange={(e)=>{handleChange(e);onChange(e)}}
                  >
                    <MenuItem value="">
                      <em>{placeholder}</em>
                    </MenuItem>
                    {data.map((row,key)=>{
                      return <MenuItem key={key} value={!byId?row.name:row[byId]}>{row.name} {row[byId]}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </>:<Typography variant="h6" ><Grid>No data in select</Grid></Typography>}
            </>
        );
}
