import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const App=()=>{
  return  <Grid container
                component="main"
                direction="row"
                className="min-height-80"
                justifyContent="center"
                >

            <Grid item xs={11}>
              <Paper sx={{ p: 2, mb:3}}>
                <Typography>
                  Hemos detectado que estos datos ya existen en la base de datos
                </Typography>
              </Paper>
            </Grid>
          </Grid>
}
export default App;
