import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper    from '@mui/material/Paper';
import Avatar   from '../common/Avatar';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import StateContext from '../../helpers/ContextState';
import Loading from '../common/Loading';
import {init,set} from '../../helpers/fninputs';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const btnSubmitId    =   'btnSubmitId'
let getInit

const App=()=>{
  const params                    =   useParams();
  const navigate                  = useNavigate();
  const location                  = useLocation();
  const context                   = React.useContext(StateContext);
  const [loading, setLoading]     = useState(true);
  const [inputs, setInputs]       = useState({});

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setInputs(response.data)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);

  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(false,{...inputs},false,true).then((response)=>{
      if (response && response.data && response.data.id && parseInt(params.id)===0) {
        navigate('/users/edit/'+response.data.id)
      }
    })
  }


  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>
            {Object.entries(inputs).length >0 || location.pathname.includes("/0")?<>
              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={11} sm={11} md={3} >
                    <Paper sx={{ p: 2, }} >
                      <Grid style={{display:'flex',justifyContent:'center',alignItems:'center',}}><Avatar/></Grid>
                      <Grid style={{display:'flex',justifyContent:'center'}}>
                        <Typography variant="h6" >
                          <b>{inputs.name}</b>
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid  item xs={11} sm={11} md={8} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Paper sx={{ p: 2, }}>
                        <Input  margin="normal"
                                required
                                fullWidth
                                type="text"
                                id="name"
                                label="Nombres y Apellidos"
                                name="name"
                                autoComplete="email"
                                autoFocus
                                defaultValue={inputs.name}
                                onChange={set}/>
                        <Grid container spacing={1}>
                          <Grid  item xs={12} md={4}>
                            <Input  margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    id="phone"
                                    label="Teléfono celular"
                                    name="phone"
                                    defaultValue={inputs.phone}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} md={4}>
                            <Input  margin="normal"
                                    type="text"
                                    fullWidth
                                    id="phone"
                                    label="Teléfono alternativo"
                                    name="alternative_telephone"
                                    defaultValue={inputs.alternative_telephone}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} md={4}>
                            <Input  margin="normal"
                                    type="email"
                                    fullWidth
                                    id="email"
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    defaultValue={inputs.email}
                                    onChange={set}/>
                          </Grid>
                        </Grid>
                        <Input  margin="normal"
                                required
                                fullWidth
                                type="text"
                                id="name"
                                label="Dirección"
                                name="address"
                                defaultValue={inputs.address}
                                onChange={set}/>
                      </Paper>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </>:false}
          </Grid>}
        </>
}
export default App
