import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Grid,Typography } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import MailIcon from '@mui/icons-material/Mail';
const App=()=>{
  const open=(url)=>{
    window.open(url)
  }
  return  <footer>
            <Grid container justifyContent="center">
              <Grid  item xs={11}>
                <Grid container>
                  <Grid item xs={12} md={4} className="mb-2">
                    <Typography variant="h4" gutterBottom component="b" className="border-bottom-primary">
                      Fesol
                    </Typography>
                    <Typography variant="h6" gutterBottom component="div">
                      Siempre contigo...
                    </Typography>
                    <Typography variant="p" gutterBottom component="div">
                      Fondo de Empleados de Supertiendas y Droguerías Olímpica S.A. 
                      FESOL <br/>Nit 860.518.842-1
                    </Typography>                    
                  </Grid>
                  <Grid item xs={12} md={4} className="mb-2">
                    <Typography variant="h5" gutterBottom component="div" className="border-bottom-primary">
                      Redes Sociales
                    </Typography>
                    <Grid container>
                      {
                        /**
                         * 
                         * 
                         * <FacebookIcon className="cursor-pointer" onClick={()=>open("https://www.facebook.com/Fondo-de-empleados-almacenes-Ol%C3%ADmpica-Fesol-337786923531154/?notif_id=1589932494328804&notif_t=page_fan")}/>
                      <TwitterIcon className="cursor-pointer" onClick={()=>open("https://twitter.com/?lang=es")}/>
                         * 
                         * 
                         */
                      }
                      
                      <InstagramIcon className="cursor-pointer" onClick={()=>open("https://www.instagram.com/instafesol/?igsh=dHlremYyeXA0Mmx0")}/>
                    </Grid>
                    <Typography className="h7 mt-2">
                      Bogotá: Cra 13 N 56 - 55 Piso 5 
                      <br/>3123281958                      
                    </Typography>
                    <Grid className="h7 mt-2">
                      <MailIcon/>servicioalasociado@fesol.com.co
                    </Grid>
                    <Grid className="h7 mt-2">
                      <AddIcCallIcon/>Línea Nacional (031) 7945394
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} className="mb-2 pt-4">
                    <Typography className="h7 mt-2">
                      Barranquilla : Cra 58 N 66 - 57  
                      <br/>3104359780                      
                    </Typography>
                    <Grid className="h7 mt-2">
                      <MailIcon/>oficinacosta@fesol.com.co
                    </Grid>                    
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={4} className="mb-2" style={{color:"#000"}}> 
                      Desarrollado por <a style={{color:"#000"}} rel="noreferrer" href="https://programandoweb.net" target="_blank">Programandoweb.net</a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </footer>
}
export default App
