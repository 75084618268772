import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PaidIcon from '@mui/icons-material/Paid';
import GroupIcon from '@mui/icons-material/Group';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


import Step1BasicForm from './forms/Step1BasicForm';
import Step2BasicForm from './forms/Step2BasicForm';
import Step3BasicForm from './forms/Step3BasicForm';
import Step4BasicForm from './forms/Step4BasicForm';
import Step5BasicForm from './forms/Step5BasicForm';
import Step6BasicForm from './forms/Step6BasicForm';

import Loading from '../../../components/common/Loading01';

import { useParams } from "react-router-dom"
import StateContext from '../../../helpers/ContextState';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

let submitResponse=false

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <AccountBoxIcon />,
    4: <GroupIcon/>,
    5: <GroupIcon/>,
    2: <AssignmentIndIcon />,
    3: <PaidIcon />,
    6: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

let inputs  = {}

export default function CustomizedSteppers() {


  const [activeStep, setActiveStep]   = React.useState(0);
  const [completed, setCompleted]     = React.useState({});
  const [submit, setSubmit]           = React.useState(false);
  const [finish, setFinish]           = React.useState(false);
  const [loading, setLoading]         = React.useState(false);

  const params    =   useParams();
  const context   =   React.useContext(StateContext);

  React.useEffect(()=>{
    if (params.id) {
      setLoading(true)
      context.get(false,{id:params.id},false,false).then((response)=>{
        if (response && response.data) {
          inputs  =   response.data
          //setInputs(response.data)
          setLoading(false)
        }
      })
    }
  },[params.id,context]);

  const setInputs=(data)=>{
    inputs[data.name]=data.value
  }


  submitResponse  = (response)  =>{
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  }

  const steps = [
                  {label:'Datos Personales',component:<Step1BasicForm submitResponse={submitResponse} activeStep={activeStep} step={0} submit={submit} setSubmit={setSubmit} setInputs={setInputs} inputs={inputs}/>},
                  {label:'Documentación',component:<Step2BasicForm submitResponse={submitResponse} activeStep={activeStep} step={1} submit={submit} setSubmit={setSubmit} setInputs={setInputs} inputs={inputs}/>},
                  {label:'Económicos',component:<Step4BasicForm submitResponse={submitResponse} activeStep={activeStep} step={2} submit={submit} setSubmit={setSubmit} setInputs={setInputs} inputs={inputs}/>},
                  {label:'Conyuge',component:<Step3BasicForm submitResponse={submitResponse} activeStep={activeStep} step={3} submit={submit} setSubmit={setSubmit} setInputs={setInputs} inputs={inputs}/>},
                  {label:'Hijos',component:<Step6BasicForm submitResponse={submitResponse} activeStep={activeStep} step={3} submit={submit} setSubmit={setSubmit} setInputs={setInputs} inputs={inputs}/>},
                  {label:'Adicionales',component:<Step5BasicForm submitResponse={submitResponse} activeStep={activeStep} step={4} submit={submit} setSubmit={setSubmit} setInputs={setInputs} inputs={inputs}/>},
                ];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {

    if (parseInt(activeStep)===1 && (!inputs.img_identification || !inputs.img_identification_back || !inputs.img_removable_payroll)) {
      return alert("Debe subir los archivos correspondientes para continuar")
    }

    if (activeStep === totalSteps()-1) {
      setFinish(true)
      context.post('api/formulario/finish_register/'+params.id,{},false,false).then((response)=>{
        // if (response && response.data) {
        //   console.log(response.data);
        // }
      })


    }else {
      setFinish(false)
      setSubmit(true)
    }
  };




  return  <>
            {loading?<Loading/>:<>
                                  <Stack sx={{ width: '100%' }} spacing={4}>
                                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                      {steps.map((label,index) => (
                                        <Step key={index}>
                                          <StepLabel StepIconComponent={ColorlibStepIcon} onClick={handleStep(index)}>{label.label2}</StepLabel>
                                        </Step>
                                      ))}
                                    </Stepper>
                                    <React.Fragment>
                                      <Paper sx={{ p: 2, mb:1}}>
                                        {allStepsCompleted() || finish?<>
                                          <Typography sx={{ mt: 2, mb: 1 }}>
                                            <div className="text-center">
                                            Gracias por completar el formulario de registro.
                                            <br/>
                                            Ahora te invitamos a conocer nuestros beneficios
                                            <br/>
                                            <Button
                                                    color="secondary"
                                                    href="https://fesol.com.co/node/17"
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}>Sólo haz click aquí</Button>
                                            </div>
                                          </Typography>
                                          </>:<>
                                          {steps[activeStep].component}
                                        </>}
                                      </Paper>
                                        <Box sx={{ display: !finish?'flex':'none', flexDirection: 'row', pt: 0 }}>
                                            { !completed[0] &&
                                              !completed[1] &&
                                              !completed[2]?<>
                                                <Button
                                                  color="inherit"
                                                  disabled={activeStep === 0}
                                                  onClick={handleBack}
                                                  sx={{ mr: 1 }}
                                                >
                                                  Anterior
                                                </Button>
                                              </> :false
                                            }

                                            <Box sx={{ flex: '1 1 auto' }} />
                                            {activeStep !== steps.length &&
                                              (completed[activeStep] ? (
                                                <Button variant="caption" sx={{ display: 'inline-block' }}>
                                                  Paso {activeStep + 1} Ya completado
                                                </Button>
                                              ) : (
                                                <Button onClick={handleComplete}>
                                                  {activeStep === totalSteps() - 1
                                                    ? 'Terminar'
                                                    : 'Guarda y Continuar'}
                                                </Button>
                                              ))}
                                          </Box>
                                    </React.Fragment>
                                  </Stack>
                                </>
              }
          </>

}
