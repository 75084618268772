import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import image from '../../assets/images/default-featured-image.png';
import StateContext from '../../helpers/ContextState';
import Input    from '../common/Input';
import {init,set} from '../../helpers/fninputs';
import { useNavigate } from "react-router-dom";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const limit = 20


export default function RecipeReviewCard({id,name,setData,value,setValue}) {
  const navigate                      = useNavigate();
  const context                 = React.useContext(StateContext);
  const [reset, setReset]       = React.useState(false);
  const [inputs, setInputs]     = React.useState({group:"banners",name:"un banner"});
  const [img, setImg]           = React.useState(false);

  init(inputs, setInputs)

  React.useEffect(()=>{
    if (value) {
      setInputs(value)
      setImg(value.url)
      setValue(false)
      setReset(true)
    }
  },[value,setValue])

  React.useEffect(()=>{
    if (reset) {
      setReset(false)
    }
  },[reset])

  React.useEffect(()=>{
    setReset(true)
  },[inputs.href])

  const handleCapture = (e,contenedor) => {
    if (e.files) {
      if (e.files.length !== 0) {
        if (e.files[0].size>=limit) {
          context.setModalShow({
                          show:true,
                          message:  <div>
                                        Imagen supera límite permitido de ({limit/1000000}) MB
                                        <Button fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={()=>context.setModalShow(false)}>Reintentar con otra</Button>
                                    </div>,
                          size:""
                        }
                      )
        }
        context.setModalShow({
                        show:true,
                        message:"Cargando imagen al sistema",
                        size:""
                      }
                    )
        const uri     =   "api/multimedia/uploadfile";
        context.post(uri,{images:e.files[0],id:id,name:name},false,true).then((response)=>{
          if (setImg && response.data) {
            if (contenedor==='img') {
              setImg(response.data)
            }else {
              let inputs_   = {...inputs}
              inputs_.href  = response.data
              setInputs(inputs_)
            }
          }
        })
      }
    }
  };

  const onSubmit=()=>{
    context.post(false,{...inputs,url:img},false,true).then((response)=>{
      if (response.data) {
        navigate('/banners')
        setInputs({})
        setImg(false)
        setData(response.data)
        setReset(true)
      }
    })
  }

  return (
    <Card>
        {!reset?<>
          <Grid className="cam-content cursor-pointer">
            <input  accept='image/*'
                    id='icon-button-file'
                    type='file'
                    capture='environment'
                    onChange={(e) => handleCapture(e.target,"img")}/>
            <CardMedia
              component="img"
              height="194"
              image={!img?image:img}
              alt="Programandoweb"
            />
          </Grid>
          <Grid className="cam-content cursor-pointer" align="center" sx={{p:2}}>
            <input  id='icon-button-file'
                    type='file'
                    capture='environment'
                    onChange={(e) => handleCapture(e.target,"pdf")}/>
            Subir archivo adjunto
          </Grid>
          <CardContent>
            <Input  margin="normal"
                    fullWidth
                    size={"small"}
                    type="text"
                    defaultValue={inputs.href}
                    label="URL"
                    name="href"
                    onChange={set}
                    />
          </CardContent>
          <CardActions disableSpacing>
            <ExpandMore
              className="cursor-pointer"
              onClick={onSubmit}
              aria-label="show more"
            >
              <SaveIcon />
            </ExpandMore>
          </CardActions>
        </>:false}
    </Card>
  );
}
