let functions     = {}
export const init=(data, setData)=>{
  functions = {data:data,setData:setData}
}
export const set=(e)=>{
  let inputs__  =   {...functions.data}
  inputs__[e.target.name] = e.target.value
  return functions.setData(inputs__)
}

/*
  required:

    e       =   events from elements
    data    =   data from inputs
  options
  functs    =  functions at dispatch

*/
export const onKeyPress=(e,functs)=>{

  let input={}
      input[e.target.name] =   e.target.value
  if (e.key === "Enter") {
    e.preventDefault()
    if (functs) {
      functs.map((row)=>{
        return row(e,input)
      })
    }
    e.target.value=""
  }

}

export const open=(url)=>{
  window.open(url)
}
