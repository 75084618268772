import {
  Routes,
  Route,
} from "react-router-dom";
import {modules_freezone} from '../helpers/modules';
import { usePrivileges } from '../helpers/usePrivileges';

const App=({  onSubmit , setOnSubmit})=>{
  return    <Routes>
              {modules_freezone.map((row,key)=>{

                if (!row.items) {
                  return  <Route key={key} path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                }else {
                  return row.items.map((row2,key2)=>{
                    if (key2===0) {
                      return  <>
                                <Route key={key}  path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                                <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                              </>
                    }else {
                      return <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                    }
                  })
                }

              })}
            </Routes>

}

export default App
