import * as React from 'react';
import { useState,useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {modules_menu} from '../../helpers/modules';
import { NavLink } from "react-router-dom";
import { useLocation,useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
let result    =   false
let result1   =   false
let result2   =   false
let result3   =   false

export default function BasicBreadcrumbs({usePrivileges,onSubmit,setOnSubmit}) {

  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const {primary,secondary}   = usePrivileges()

  useEffect(() => {

    if (primary) {
      let items_  =   []
      /*
        busco entre los items de modulos basado en la url cuál está activo
        de allí extraigo los datos para el segudo elemento del Breadcrumbs
      */
      //result      =   modules.find((search)=>search.slug===location.pathname || (search.items && search.items.find((search2)=>search2.slug===location.pathname)));
      result      =   primary;
      if (result && result.items) {
        result1    =   secondary
      }

      /*
        Al igual que el anterior, busco dentro de los módulos de menu
      */
      result2     =   modules_menu.find((search)=>search.slug===location.pathname);

      if (result && result.slug!=='/') {
        items_.push({
          name:result.name,
          slug:result.slug,
        })
      }

      if (result && result.items && !result1) {
        items_.push({
          name:"Listado",
        })
      }else if (result && result.items && result1) {
        items_.push({
          name:result1.name,
        })
      }

      if (result2 && result2.slug!=='/') {
        items_.push({
          name:result2.name,
          slug:result2.slug,
        })
      }
      setItems(items_)

      let splits  =   location.pathname.split("/")
      let search_ =   '/'+splits[1]+'/'+splits[2]
      if (primary && primary.items) {
        result3     =   primary.items.find((search)=>search.slug.includes(search_));
      }
    }



  }, [location.pathname,primary,secondary]);

  //console.log(items);

  return (
    <>{
        items.length>0?<Grid  container
                              component="main"
                              direction="row"
                              justifyContent="center"
                              >
                          <Grid item xs={11} component={Paper} sx={{ mt: 1, p:1, mb:1 }}>
                            <Grid  container direction="row">
                              <Grid item xs={10}>
                                <div role="presentation" onClick={handleClick}>
                                  <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                                    <NavLink underline="hover" color="inherit" to="/">
                                      Home
                                    </NavLink>
                                    {items.map((row,key)=>{
                                      if (row.slug) {
                                        return  <NavLink underline="hover" color="inherit" to={row.slug} key={key}>
                                                  {row.name}
                                                </NavLink>
                                      }else {
                                        return  <Typography className="title-secondary" color="text.primary" key={key}>{row.name}</Typography>
                                      }
                                    })}
                                  </Breadcrumbs>
                                </div>
                              </Grid>
                              <Grid item xs={2} >
                                <Grid container direction="row" justify="flex-end" style={{ display: "flex", justifyContent: "flex-end" }}>
                                  {result && result.add && (  location.pathname.includes("/add") ||
                                                              location.pathname.includes("/view") ||
                                                              location.pathname.includes("/edit")
                                                            )?<Grid item  xs={2} className="cursor-pointer" onClick={()=>navigate((result3 && result3.parent)?result3.parent:-1)}>
                                      <CloseIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                                  </Grid>:false}
                                  {result && result.add && (!location.pathname.includes("/orders/add") && !location.pathname.includes("/orders/add/0") && !location.pathname.includes("/orders/view/")) && (  location.pathname.includes("/add") ||
                                                              location.pathname.includes("/view") ||
                                                              location.pathname.includes("/edit"))?<Grid item  xs={2} className="cursor-pointer" onClick={()=>setOnSubmit(true)}>
                                      <SaveIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                                  </Grid>:false}
                                  {result && result.pdf && !location.pathname.includes("/add") && !location.pathname.includes("/view") && !location.pathname.includes("/edit")?<Grid item  xs={2}>
                                    <PrintIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                                  </Grid>:false}
                                  {result && result.excel && !location.pathname.includes("/add")  && !location.pathname.includes("/view") && !location.pathname.includes("/edit")?<Grid item  xs={2}>
                                    <ReceiptIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                                  </Grid>:false}
                                  { (primary && primary.add) &&
                                    (secondary && secondary.add) &&
                                    (primary.add===secondary.add || primary.add==='any') ?<Grid item  xs={2}>
                                    <NavLink to={(primary.add===secondary.add && result.add)?result.add:primary.add==='any'?secondary.add:"#"}>
                                      <AddCircleIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                                    </NavLink>
                                  </Grid>:false}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>:false
      }
    </>
  );
}
