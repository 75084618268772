import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper    from '@mui/material/Paper';
import Avatar   from '../common/Avatar';
import Input    from '../common/Input';
import {init,set} from '../../helpers/fninputs';
const App=()=>{
  const [data, setData]           = useState({});

  init(data, setData)

  return  <Grid sx={{ flexGrow: 1 }} container spacing={0}>
            <Grid item xs={12} sx={{ mt: 1, }}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11} sm={11} md={3} >
                  <Paper sx={{ p: 2, }} >
                    <Grid style={{display:'flex',justifyContent:'center',alignItems:'center',}}><Avatar/></Grid>
                    <Grid style={{display:'flex',justifyContent:'center'}}>
                      <Typography variant="h6" >
                        <b>{data.name}</b>
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid  item xs={11} sm={11} md={8} >
                  <Paper sx={{ p: 2, }}>
                    <Input  margin="normal"
                            required
                            fullWidth
                            type="text"
                            id="name"
                            label="Nombres y Apellidos"
                            name="name"
                            autoComplete="email"
                            autoFocus
                            onChange={set}/>
                    <Grid container spacing={1}>
                      <Grid  item xs={4}>
                        <Input  margin="normal"
                                required
                                fullWidth
                                type="text"
                                id="phone"
                                label="Teléfono celular"
                                name="phone"
                                onChange={set}/>
                      </Grid>
                      <Grid  item xs={4}>
                        <Input  margin="normal"
                                type="text"
                                fullWidth
                                id="phone"
                                label="Teléfono alternativo"
                                name="phone"
                                onChange={set}/>
                      </Grid>
                      <Grid  item xs={4}>
                        <Input  margin="normal"
                                type="email"
                                fullWidth
                                id="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="email"
                                onChange={set}/>
                      </Grid>
                    </Grid>
                    <Input  margin="normal"
                            required
                            fullWidth
                            type="text"
                            id="name"
                            label="Dirección"
                            name="address"
                            onChange={set}/>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
