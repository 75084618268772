import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { NavLink } from "react-router-dom";


const App=({id,label,data})=>{
  return  <Paper sx={{ p: 2, }}>
            <Grid>
              <Grid className="feature_gray m-0 mb-1">
                <Grid  item xs={12} className="mb-05">
                    Cliente: <b>{data.client_name}</b>
                </Grid>
                <Grid  item xs={12} className="mb-05">
                    Técnico: <b>{data.technician_name}</b>
                </Grid>
                <Grid  item xs={12} className="mb-05">
                    Equipo: <b>{data.name}</b>
                </Grid>
                <Grid  item xs={12} className="mb-05">
                    Sujeto: <b>{data.subject}</b>
                </Grid>
                <Grid  item xs={12} className="mb-2">
                    Descripción completa: <b>{data.fulltext}</b>
                </Grid>
                <Grid  item xs={12} className="mb-05">
                    <NavLink to={"/orders/viewbyuser/"+data.orders_assignments_id}>Ir a la order de servicio</NavLink>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
}
export default App
