import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Loading from '../common/Loading';
import StateContext from '../../helpers/ContextState';
import ItemsComments from '../comments/Items';
const App=()=>{
  const context  = React.useContext(StateContext);
  const [data, setData]         =   useState({parents:[],comments:[]});
  const [loading, setLoading]   =   useState(true);

  useEffect(() => {
    if (context.user && context.user.access_token) {
      setLoading(false)
      setData({parents:[],comments:[]})
      // setTimeout(function(){
      //   context.post("api/comments/rooms",{take:3,access_token:context.user.access_token},false,false).then((response)=>{
      //     if (response.data) {
      //       setData(response.data)
      //       setLoading(false)
      //     }
      //   })
      // }, 2000);
    }

  },[context]);

  return  <>
            {
              loading?<Loading height={'60vh'}/>:<>
                <Grid sx={{ flexGrow: 1 }} container spacing={0} justifyContent="center">
                  <Grid item xs={11} sx={{ mt: 1, }}>
                    <Grid container  spacing={2}>
                        {data.comments.map((row,key)=>{
                          return  <Grid item xs={12} md={4} key={key}>
                                    <ItemsComments  data={data.parents[key]}
                                                    id={row.token_relation}
                                                    label="Mensaje, sugerencia, descripción o cotización"/>
                                  </Grid>
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }
          </>
}
export default App
