import * as React from 'react';
import {useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';
const App=({  onChange,
              label,
              name,
              margin,
              type,
              autoFocus,
              fullWidth,
              required,
              autoComplete,
              color,
              size,
              multiline,
              rows,
              defaultValue,
              className,
              min,
              max,
              onKeyPress,
              disabled,
              InputProps})=>{
  const [reset, setReset]               = useState(false)
  const [changeColors, setChangeColors] = useState(false)

  useEffect(()=>{
    if (reset) {
      setReset(false)
    }
    if (defaultValue && defaultValue!=='0000-00-00' && defaultValue!=='null') {
      setChangeColors(true)
    }else {
      setChangeColors(false)
    }
  },[reset])

  const changeColor=(e)=>{
    if (e.target.value!=='' && e.target.value!=='0000-00-00' && e.target.value!=='null') {
      setChangeColors(true)
    }else {
      setChangeColors(false)
    }
  }

  return  <>{!reset?<TextField  margin={margin}
                      fullWidth={fullWidth}
                      multiline={multiline}
                      defaultValue={defaultValue}
                      className={!changeColors?"fecha_actual":"fecha_actual active"}
                      size={size}
                      rows={rows}
                      type={"date"}
                      color={(color)?color:"secondary"}
                      id={name}
                      label={label}
                      sx={{m:1,width:(multiline)?"98.6%":"97.5%"}}
                      name={name}
                      autoComplete={autoComplete}
                      autoFocus={autoFocus}
                      onChange={(e)=>{onChange(e); changeColor(e)}}
                      onKeyPress={onKeyPress}
                      InputProps={InputProps}
                    />:false}
          </>
}
export default App
