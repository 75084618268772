import React,{useState,useEffect,useCallback} from 'react';
import './App.css';
//import Login from './components/auth';
import In from './components/In';
import FreeZone from './components/FreeZone';
import Modal from './components/common/Modal';
import Loading from './components/common/Loading';
import Snackbars from './components/common/Snackbars';
import {theme} from './helpers/theme';
import { ThemeProvider } from '@mui/material/styles';
import StateContext from './helpers/ContextState';
import {useStore} from './helpers/useStore';
import {modules} from './helpers/modules';
//import { io } from "socket.io-client";
import localDatabase from './helpers/indexedDB';


import {  get,
          getRemote,
          post,
          put,
          del,
          setFunctions
        } from './helpers/api';


import {
  BrowserRouter as Router
} from "react-router-dom";

let   csrf_token  =   false
const timeout     =   5000
let   intervalID;
let   init

let   socket
let   position_   =    {
                          latitude:"",
                          longitude:""
                        }
const freeZone    =     document.location.pathname.includes("open")
const  App=()=>{
  const store                                 =   useStore();
  const [user, setUser]                       =   useState(false);
  const [token, setToken]                     =   useState(false);
  const [loading, setLoading]                 =   useState(true);
  const [snackbars, setSnackbars]             =   useState(false);
  const [modalShow, setModalShow]             =   useState(false);
  const [openMessage, setOpenMessage]         =   useState(false);
  const [internet, setInternet]               =   useState(true);
  const [onSubmit, setOnSubmit]               =   useState(false);
  const [position, setPosition]               =   useState(position_);
  const [menus, setMenus]                     =   useState([]);
  /*voy a instanciar la base de datos local*/
  useEffect(()=>{
    localDatabase.createConnection(process.env.REACT_APP_DBNAME)
  },[])

  useEffect(()=>{
    let result    =   modules.find((search)=>search.slug===document.location.pathname);
    if (result) {
      document.title =  process.env.REACT_APP_NAME + " - " +result.name
    }else {
      document.title =  process.env.REACT_APP_NAME
    }
  },[])

  // /*inicialización del socket y sus métodos*/
  // useEffect(()=>{
  //   //luego colocarlo al web
  //   if (admin) {
  //     socket        =   io( process.env.REACT_APP_SOCKET,{ transports:  ['websocket'], reconnectionDelayMax: 10000,});
  //     socket.on('allUsersLocations', function () {
  //       navigator.geolocation.getCurrentPosition((position)=>{
  //         //console.log(position.coords);
  //         socket.emit("geolocation",  {   ...user,
  //                                         latitude:position.coords.latitude,
  //                                         longitude:position.coords.longitude
  //                                     })
  //       }, function(error){});
  //     })
  //   }
  //
  // },[user])


  const success = useCallback((position) => {

    position_   =   {
                      latitude:position.coords.latitude,
                      longitude:position.coords.longitude
                    }
    setPosition(position_)

  }, []);

  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(success, function(error){});
  },[success])

  useEffect(()=>{
    if (user && navigator.geolocation) {
      navigator.geolocation.watchPosition(function(position) {
        if ((user && user.token!==undefined)&&position&&position.coords&&position.coords.latitude&&position.coords.longitude) {
          /*
            lo detuve porque manda muchas peticiones al servidor
            en su lugar se lo dejé al socket
          */
          // post('api/users/setGeolocation',{  id:user.token,
          //                                   latitude:position.coords.latitude,
          //                                   longitude:position.coords.longitude
          //                                 },false,false).then((response)=>{
          //
          // })
          // socket.emit("geolocation",  { ...user,
          //                               latitude:position.coords.latitude,
          //                               longitude:position.coords.longitude
          //                             })
        }
      });
    }
  },[user,success])

  // const onClick=()=>{
  //   socket.emit("geolocation",  { ...user,
  //                                 latitude:position.latitude,
  //                                 longitude:position.longitude
  //                               })
  // }

  useEffect(()=>{
    /*
        ESTE EVENTO ESTÁ DIRIGIDO AL BOTÓN GUARDAR EN components/common/Breadcrumbs
        al hacer click ahí, se dispara este evento que está propagado por el ContextState
        y en cada formulario donde lo invoquen, se haga el respectivo api.post()

        Si los formularios no lo invocan, de igual manera a los 4 segundo se vuelve al state false
    */
    if (onSubmit) {
      setTimeout(function(){
        setOnSubmit(false)
      }, 4000);
    }
  },[onSubmit])

  useEffect(()=>{
    if (!user && store.get("user").name) {
      setUser(store.get("user"))
    }else if (user && !store.get("user").name) {
      store.set("user",user)
    }else if (user && user==='close' && store.get("user").name) {
      store.clear()
      setUser(false)
    }

    if (user && user!=='close' && socket) {
      /*debo reenviar los datos del usuario si hago F5*/
      socket.emit("registeruser",{  ...user,
                                    latitude:position_.latitude,
                                    longitude:position_.longitude
                                  })
    }

  },[user,store])

  useEffect(()=>{
    setFunctions({
                    localDatabase:localDatabase,
                    position:position,
                    store:store,
                    setOpenMessage:setOpenMessage,
                    internetStatus:internetStatus,
                    setLoading:setLoading,
                    setModalShow:setModalShow,
                    user:(user)?user:store.get("user"),
                  });

    if (!csrf_token) {
      csrf_token=true
      init()
    }

  },[store,user,position])

  useEffect(()=>{
    if (openMessage) {
      setSnackbars({
        show:true,
        message:openMessage,
      })
    }else {
      setSnackbars(false)
    }
  },[openMessage])

  useEffect(()=>{
    if (!internet) {
      intervalID = setInterval(function(){
        init()
      }, timeout);
    }
  },[internet])

  const internetStatus=(response)=>{
    if (response.message!=='Network Error') {
      clearInterval(intervalID);
      setOpenMessage(false)
      setInternet(true)
    }else if (response.message==='Network Error') {
      setOpenMessage("Estamos presentando inconvenientes de conexión...")
      setInternet(false)
    }
  }

  init=()=>{
    get("api/menu/all",{},false,false).then((response)=>{
      setMenus(response.data)
    })
    get("api/csrf_token",{},false,false).then((response)=>{
      csrf_token  = response.csrf_token

      if (response.csrf_token) {
        clearInterval(intervalID);
        //setOpenApplication(true)
        setInternet(true)
      }

      /*seteo las funciones dentro del api*/
      setFunctions({
                      position:position,
                      setLoading:setLoading,
                      setModalShow:setModalShow,
                      csrf_token:csrf_token,
                      user:(user)?user:store.get("user"),
                      store:store,
                    });


    })
  }

  const provider=()=>{
    return {  user:user,
              onSubmit:onSubmit,
              setOnSubmit:setOnSubmit,
              setUser:setUser,
              setModalShow:setModalShow,
              setSnackbars:setSnackbars,
              token:token,
              setToken:setToken,
              get:get,
              getRemote:getRemote,
              post:post,
              socket:socket,
              position:position,
              put:put,
              menus:menus,
              delete:del}
  }

  return  <ThemeProvider theme={theme}>
            {modalShow?<Modal open={modalShow} setOpen={setModalShow}/>:false}
            <StateContext.Provider value={provider()}>
              {!loading?<>
                <Router>
                  {freeZone?<>
                      <FreeZone onSubmit={onSubmit} setOnSubmit={setOnSubmit}/>
                    </>:!freeZone?<In user={user} onSubmit={onSubmit} setOnSubmit={setOnSubmit}/>:false}
                </Router>
              </>:false}
              {loading?<Loading setLoading={setLoading} loading={loading}/>:false}
              {snackbars?<Snackbars setOpen={setSnackbars} open={snackbars}/>:false}

            </StateContext.Provider>
          </ThemeProvider>

}

export default App;
