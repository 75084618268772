import React from 'react';
import { useEffect,useCallback } from 'react';
import StateContext from '../../helpers/ContextState';
import { useNavigate } from 'react-router-dom';
const App=()=>{
  let navigate  = useNavigate();
  const context = React.useContext(StateContext);


  const handleClose = useCallback(() => {
    //context.socket.emit('unregisteruser',context.user);
    context.setUser("close")
    context.setToken(false)
    navigate("/")
    context.post("api/exit",{...context.user},false,false).then((response)=>{
      if (response.code &&  (response.code==='SUCCESS' || response.message==="Request failed with status code 401")) {

      }
    })
  }, [context,navigate]);

  useEffect(()=>{
    handleClose()
  },[handleClose])

  return <div></div>
}
export default App
