import * as React from 'react';
import {useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import StateContext from '../../helpers/ContextState';
import Loading from '@mui/material/LinearProgress';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NavLink } from "react-router-dom";



import Search from './Search';

import {usePrivileges} from '../../helpers/usePrivileges';

const ICONS           = {
  "edit":EditIcon,
  "del":DeleteIcon,
  "view":SearchIcon,
  "activity":AdUnitsIcon,
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

let getInit
const queryString   =   window.location.search;
const urlParams     =   new URLSearchParams(queryString);
let current         =   (parseInt(urlParams.get('page')))?parseInt(urlParams.get('page')):1
//let bold            =   ""
export default function CustomizedTables({td,extra,endpoint,reload,setReload,add}) {
  const {secondary} = usePrivileges();
  const location = useLocation();
  const navigate = useNavigate();
  const context  = React.useContext(StateContext);
  const [rows, setRows]                 = useState([]);
  const [pages, setPages]               = useState([]);
  const [loading, setLoading]           = useState(false);

  useEffect(() => {
    if (reload && setReload) {
      setReload(false)
      getInit(true)
    }
  }, [reload,setReload]);

  useEffect(() => {
    current = 1
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, [location.pathname]);

  getInit=(mounted,filter)=>{
    setLoading(true)
    if (filter || filter==='') {
      setPages([])
    }
    let endpoint_ = endpoint?endpoint:"api"+location.pathname+location.search
    let ext       = ""
    if (filter===undefined) {
      filter=""
    }
    if (!endpoint_.includes("?page")) {
      //bold   =   filter
      ext   +=  "?search="+filter
    }else {
      //bold   =   filter
      ext   +=  "&search="+filter
    }
    context.get(endpoint_+ext,{...context.user},false,false).then((response)=>{
      if (response.data && response.data.data) {
        setRows(response.data.data)
      }
      if (response.data && response.data.links && pages.length===0) {
        setPages(response.data.links)
      }
      setLoading(false)
    })
  }

  const onChange=(event,page)=>{
    location.search="?page="+page
    navigate(location.pathname+"?page="+page)
    getInit(true)
  }


  const deleteItem=(row)=>{
    context.setModalShow({
                    show:true,
                    title:"Atención",
                    message:  <Grid container direction="row">
                                <Grid item xs={12} sx={{ mt: 1,mb: 1, p:0 }}>
                                  ¿Seguro desea eliminar este registro?
                                </Grid>
                                <Grid item xs={6}>
                                  <Button variant="contained" color="secondary" onClick={()=>{deleteSend(row);context.setModalShow(false);}}>Si</Button>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button variant="contained" color="primary" onClick={()=>context.setModalShow(false)}>No</Button>
                                </Grid>
                              </Grid>,
                    size:""
                  }
                )

  }

  const deleteSend=(row)=>{
    context.post("api"+location.pathname+"/delete",{...context.user,...row},false,false).then((response)=>{
      if (response && response.data) {
        getInit(true)
      }
    })
  }

  return (
    <>
      <Search placeHolder="Buscar..." name="search" loading={loading} getInit={getInit} add={add}/>
      <TableContainer component={Paper}>
        {td?<>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {td.map((row,key)=>{
                  return  <StyledTableCell key={key} width={row.label==='Acción'?100:""}  align={row.align?row.align:"left"}>
                            {row.label}
                          </StyledTableCell>
                })}
              </TableRow>
            </TableHead>
            {
              !loading?<TableBody>
                          {rows.map((row,key1) => (
                            <StyledTableRow key={key1}>
                              {td.map((row2,key2)=>{
                                let custom  = false
                                if (row2.value && row2.value.includes(".")) {
                                  const spliter =   row2.value.split(".")
                                  if (row && spliter && spliter.length>0 && spliter[0] && spliter[1] && row[spliter[0]] && row[spliter[0]][spliter[1]]) {
                                    custom        =   row[spliter[0]][spliter[1]];
                                  }
                                }
                                return  <StyledTableCell key={key2} width={row2.label==='Acción'?100:""}  align={row2.align?row2.align:"left"}>
                                            {(row && row2.value) && row[row2.value]?<>
                                                {row[row2.value]}
                                              </>:<>
                                              {row2 && row2.items?<>
                                                {row2.items.map((row3,key3)=>{
                                                  // if (secondary && !secondary[row3]) {
                                                  //   return false
                                                  // }
                                                  let HtmlIcon

                                                  if (ICONS[row3]) {
                                                    HtmlIcon=ICONS[row3]
                                                  }else {
                                                    return <span key={key3}>{row3}</span>
                                                  }

                                                  if (row2.event) {
                                                    return <span key={key3} onClick={()=>deleteItem(row)} className="cursor-pointer"><HtmlIcon></HtmlIcon></span>
                                                  }

                                                  if (row3==='delete') {
                                                    return  <span key={key3} onClick={()=>deleteItem(row)} className="cursor-pointer"><HtmlIcon></HtmlIcon></span>
                                                  }else {
                                                    return  <NavLink key={key3} to={(secondary && secondary[row3]) && row.token?secondary[row3]+'/'+row.token:(secondary && secondary[row3]) && !row.token?secondary[row3]+'/'+row.id:"#"}><HtmlIcon></HtmlIcon></NavLink>
                                                  }
                                                })}
                                              </>:row2.value.includes(".")?<>{custom}</>:false}
                                            </>}
                                        </StyledTableCell>
                              })}
                            </StyledTableRow>
                          ))}
                        </TableBody>:false
            }

          </Table>
          {loading?<Loading color="secondary"/>:false}
          <Grid container direction="row">
            <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
              <Pagination defaultPage={current}
                          onChange={onChange}
                          count={(pages.length===3?pages.length-2:pages.length-2)}
                          shape="rounded"
                          color="secondary"
                          siblingCount={1}
                          boundaryCount={1}/>
            </Grid>
          </Grid>
        </>:false}
      </TableContainer>
    </>
  );
}
