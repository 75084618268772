import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Paper    from '@mui/material/Paper';
import BtnSubmit from '../BtnSubmit';
import InfoEquipments from '../orders/InfoEquipments';
import Select from '../Select';
import Delete from '../Delete';
import Loading from '../Loading01';
import Loading2 from '../Loading';
import Button from '@mui/material/Button';
import {init,set} from '../../../helpers/fninputs';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Calendar from '../Calendar';



const btnSubmitId    =   'btnSubmitId'
let getInit

const App=({context,id,client_id,setReload})=>{

  const [technician, setTechnician]       = useState([]);
  const [order_details, setOrder_details] = useState([]);
  const [equipments, setEquipments]   = useState(false);
  const [loading, setLoading]         = useState(true);
  const [loading2, setLoading2]       = useState(false);
  const [inputs, setInputs]           = useState({});
  const [addEquipament, setAddEquipament]       = useState(false);
  const [addAvailability, setAddAvailability]   = useState(false);

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{
    setLoading2(true)
    context.get("api/orders/add/0?client_id="+client_id,{...context.user},false,false).then((response)=>{
      setLoading2(false)
      if (response.data) {
        if (response.data.equipments) {
          setEquipments(response.data.equipments)
          delete(response.data.equipments)
        }
        if (response.data.technician) {
          setTechnician(response.data.technician)
          delete(response.data.technician)
        }
        if (response.data.order_details) {
          setOrder_details(response.data.order_details)
          delete(response.data.order_details)
        }
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()
    context.post("api/orders/createByAdmin/0",{  ...inputs,client_id:client_id},false,false).then((response)=>{
      if (response && response.data && response.data.order && response.data.order.id) {
        setInputs({...response.data.order})
      }
    })
  }

  const handleClick=(name)=>{
    setAddEquipament(true)
  }

  const handleClickAvaible=()=>{
    setAddAvailability(true)
  }

  return  <>{!addEquipament && !addAvailability?<>
                                {loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

                                  <Grid item xs={12} sx={{ mt: 1, }}>
                                    <Grid container justifyContent="center" spacing={2}>
                                      <Grid  item xs={11} sm={11} md={11} >
                                        <form onSubmit={onSubmit}>
                                          <BtnSubmit id={btnSubmitId}/>
                                          <Paper sx={{ p: 2, }}>
                                            <Grid container spacing={0}>
                                              <Grid  item xs={12} md={12}>
                                                <Select data={technician}
                                                        disabled={inputs.token?true:false}
                                                        name="technician_id"
                                                        required={true}
                                                        byId={"id"}
                                                        defaultValue={inputs.technician_id}
                                                        placeholder='Técnico'
                                                        inputs={inputs}
                                                        setInputs={set}
                                                        label="Técnico"
                                                        value={inputs.technician_id} />
                                              </Grid>

                                              <Grid  item xs={12} md={12}>
                                                {!inputs.order_date && !inputs.start?<Grid sx={{ ml:1 }}><Button variant="contained" color="secondary" onClick={handleClickAvaible} sx={{ mr:2 }}>
                                                  <AddCircleIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> Asignar Fecha
                                                  {/*inputs.order_date*/}
                                                  {/*inputs.estimated_time*/}
                                                </Button></Grid>:<Grid>
                                                  <Grid className="simula_input">
                                                    Asignación {inputs.order_date}
                                                  </Grid>
                                                </Grid>}
                                              </Grid>
                                              {
                                                client_id && inputs.technician_id?<>
                                                  {loading2?<Loading2 height={'60vh'}/>:false}
                                                  <Grid container spacing={0} justifyContent="center">
                                                      {!equipments?<>
                                                        </>:equipments && equipments.length===0?<>
                                                          Este cliente no tiene equipos registrados
                                                        </>:<>
                                                          {
                                                            inputs.token && order_details.length>0?<>
                                                              <Grid  item xs={12}>
                                                                <Grid className="feature_invert">
                                                                  <Grid container>
                                                                    <Grid  item xs={12} md={2}>
                                                                      Equipo
                                                                    </Grid>
                                                                    <Grid  item xs={12} md={1}>
                                                                      Nombre de usuario
                                                                    </Grid>
                                                                    <Grid  item xs={12} md={1}>
                                                                      Contraseña
                                                                    </Grid>
                                                                    <Grid  item xs={12} md={1}>
                                                                      Fenix
                                                                    </Grid>
                                                                    <Grid  item xs={12} md={1}>
                                                                      Asunto del problema
                                                                    </Grid>
                                                                    <Grid  item xs={12} md={5}>
                                                                      Descripción del problema
                                                                    </Grid>
                                                                    <Grid  item xs={12} md={1}>
                                                                      Acciones
                                                                    </Grid>
                                                                  </Grid>
                                                                </Grid>
                                                              </Grid>
                                                              {order_details.map((row,key)=>{
                                                                return <Grid  item xs={12} key={key}>
                                                                          <Grid className="feature_gray">
                                                                            <Grid container>
                                                                              <Grid  item xs={12} md={2}>
                                                                                {row.equipment.name}
                                                                              </Grid>
                                                                              <Grid  item xs={12} md={1}>
                                                                                {row.equipment.equipment_username}
                                                                              </Grid>
                                                                              <Grid  item xs={12} md={1}>
                                                                                {row.equipment.equipment_password}
                                                                              </Grid>
                                                                              <Grid  item xs={12} md={1}>
                                                                                {row.equipment.equipment_fenix}
                                                                              </Grid>
                                                                              <Grid  item xs={12} md={1}>
                                                                                {row.subject}
                                                                              </Grid>
                                                                              <Grid  item xs={12} md={5}>
                                                                                {row.fulltext}
                                                                              </Grid>
                                                                              <Grid  item xs={12} md={1}>
                                                                                <Delete row={row} uri="api/orders/deleteItemOrder"/>
                                                                              </Grid>
                                                                            </Grid>
                                                                          </Grid>
                                                                        </Grid>
                                                              })}
                                                            </>:false
                                                          }

                                                        </>}
                                                  </Grid>
                                                  {inputs && (inputs.start && inputs.end) && (!inputs.ordersAssignments || (inputs.ordersAssignments && parseInt(inputs.ordersAssignments.status)===1))?<>
                                                      {
                                                        inputs.token?<Grid  item xs={12} md={12} sx={{ mt: 2,ml:1 }}>

                                                                    </Grid>:<Grid  item xs={6}>
                                                                      <Button variant="contained" color="secondary" type="submit">
                                                                        <SaveIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> Crear orden y continuar
                                                                      </Button>
                                                                    </Grid>
                                                      }
                                                    </>:<Grid sx={{ ml:1 }}>
                                                    {inputs.order_date?<Button variant="contained" color="secondary" onClick={handleClick}>
                                                      <AddCircleIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> Info equipos a reparar
                                                    </Button>:false}
                                                  </Grid>}
                                                </>:false
                                              }
                                            </Grid>
                                          </Paper>
                                        </form>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>}
              </>:addEquipament && !addAvailability?<>
              <InfoEquipments setReload={setReload} setAddEquipament={setAddEquipament} setOrder_details={setOrder_details} data={equipments} inputs={inputs} setInputs={set} context={context}/>
            </>:!addEquipament && addAvailability?<>
              <Calendar setAddAvailability={setAddAvailability} setAddEquipament={setAddEquipament} setOrder_details={setOrder_details} data={equipments} inputs={inputs} setInputs={setInputs} context={context}/>
            </>:false}
          </>
}
export default App
