import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Paper    from '@mui/material/Paper';
import '../../App.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

require("moment/locale/es.js")

const localizer = momentLocalizer(moment)

let getInit

const App = ({context,setAddEquipament,setOrder_details,data,inputs,setInputs,setAddAvailability}) => {
  const [myEventsList, setMyEventsList]               = useState([]);
  const [reset, setReset]                             = useState(true);

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  // useEffect(() => {
  //   if (reset) {
  //     setReset(false)
  //   }
  // }, [reset]);

  getInit=(mounted)=>{
    //setLoading(true)
    setReset(true)
    context.post("api/calendar",{...context.user},false,false).then((response)=>{
      if (response.data) {
        if (response.data && response.data.orders_assigned) {
          //setOrders_assigned(response.data.orders_assigned)
          if (response.data.orders_assigned.length>0) {
            let asign   =   []
            response.data.orders_assigned.map((row,key)=>{
              return asign.push({
                title: row.title,
                start: new Date(row.start),
                end: new Date(row.end),
                uri:row.orders_assignments_token
              })
            })
            setMyEventsList(asign)
            setReset(true)
          }
        }
      }
      setReset(false)
    })
  }

  const onEventDrop = (data) => {
    //console.log(data,"jorge");
  };

  const openEventForm=(data)=>{
    const now         =   new Date();
    const startFormat =   new Date(data.start)
    if (now>startFormat) {
      return false;
    }

    const endFormat   =   new Date(data.end)

    let year      =   startFormat.getFullYear()
    let month     =   startFormat.getMonth()+1>=10?startFormat.getMonth()+1:"0"+(startFormat.getMonth()+1)
    let day       =   startFormat.getDate()>=10?startFormat.getDate():"0"+startFormat.getDate()
    let hours     =   startFormat.getHours()>=10?startFormat.getHours():"0"+startFormat.getHours()
    let minute    =   startFormat.getMinutes()>=10?startFormat.getMinutes():"0"+startFormat.getMinutes()
    const seconds   =   "00"
    const setTimeFormatStart  =  year+"-"+month+"-"+day+" "+hours+":"+minute+":"+seconds

    year      =   endFormat.getFullYear()
    month     =   endFormat.getMonth()>=10?endFormat.getMonth()+1:"0"+(endFormat.getMonth()+1)
    day       =   endFormat.getDate()>=10?endFormat.getDate():"0"+endFormat.getDate()
    hours     =   endFormat.getHours()>=10?endFormat.getHours():"0"+endFormat.getHours()
    minute    =   endFormat.getMinutes()>=10?endFormat.getMinutes():"0"+endFormat.getMinutes()

    const setTimeFormatEnd    =  year+"-"+month+"-"+day+" "+hours+":"+minute+":"+seconds
    setInputs({...inputs,start:setTimeFormatStart,end:setTimeFormatEnd})
    setAddAvailability(false)
  }

  return <Grid sx={{ flexGrow: 1 }} container spacing={0}>
            <Grid item xs={12} sx={{ mt: 1, }}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  <Paper sx={{ p: 2, }} className="max-height-400">
                    <Grid>
                      {!reset?<Calendar
                        resizable
                        step={30}
                        timeslots={2}
                        defaultView="week"
                        localizer={localizer}
                        events={myEventsList}
                        startAccessor="start"
                        onEventDrop={onEventDrop}
                        endAccessor="end"
                        onSelectSlot={(slotInfo) => {
                          openEventForm(slotInfo)
                        }}
                        views={[  "week", "day" ]}
                        selectable
                        onSelectEvent={(slotInfo)=>openEventForm(slotInfo)}
                        messages={{
                          next: "Siguiente",
                          previous: "Anterior",
                          today: "Hoy",
                          month: "Mes",
                          week: "Semana",
                          day: "Día",
                        }}
                      />:false}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}

export default App
