import * as React from 'react';
import {useState,useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper    from '@mui/material/Paper';
import Button    from '@mui/material/Button';
import StateContext from '../../helpers/ContextState';
import TextField from '@mui/material/TextField';
import Comments from '../comments';
import { useNavigate } from "react-router-dom";
import {
          inputs,
          useInputs
        } from '../../helpers/useInputs';
const App=()=>{

  const context               = React.useContext(StateContext);
  const [data, setData]   = useState({});
  const navigate = useNavigate();


  useEffect(() => {
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
      }
    })
  }, [context]);

  const onSubmit=(e)=>{
    e.preventDefault()
    context.post("api/open/orders/close",{...inputs,...data},false,true).then((response)=>{
      if (response.data) {
        navigate('/')
      }
    })
  }

  return  <Grid sx={{ flexGrow: 1 }} container spacing={0} justifyContent="center" >
            <Grid item xs={11} md={8} sx={{ mt: 4, }}>
              <Typography
                variant="h4"
                className="text-center text-gray mb-2">
                Cliente
              </Typography>
              <Paper sx={{ p: 2, mb:3 }}>
                <Typography
                  variant="h6"
                  className="text-center">
                  {data.client_name}<br/>
                  {data.client_phone}
                </Typography>
              </Paper>
              <Typography
                variant="h4"
                className="text-center text-gray mb-2">
                Técnico
              </Typography>
              <Paper sx={{ p: 2, mb:3 }}>
                <Typography
                  variant="h6"
                  className="text-center">
                  {data.technician_name}<br/>
                  {data.technician_phone}
                </Typography>
              </Paper>
              <Typography
                variant="h4"
                className="text-center text-gray mb-2">
                Equipos
              </Typography>
              {data.equipments?<>
                {data.equipments.map((row,key)=>{
                  return  <Paper sx={{ p: 2, mb:3 }} key={key}>
                            <Typography
                              variant="h6"
                              className="text-center">
                              {row.name}
                            </Typography>
                            <div>Título: <b>{row.subject}</b></div>
                            <div>Descripción: <b>{row.fulltext}</b></div>
                            <div>
                              <Comments id={"table/orders_details/"+row.orders_details_id} label="Mensaje, sugerencia, descripción o cotización"/>
                            </div>
                          </Paper>
                })}
              </>:false}
              {data && data.status<2?<form onSubmit={onSubmit}>
                <Grid>
                  <Paper  sx={{ p: 2, mb:3 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombres y apellidos de quién recibe"
                      name="name"
                      autoFocus
                      onChange={useInputs}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="phone"
                      label="Teléfono de contacto"
                      name="phone"
                      onChange={useInputs}
                    />
                    <TextField
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                      min={1}
                      max={10}
                      id="value"
                      label="Calificación del 1 al 10"
                      name="value"
                      inputProps={{min:5, max: 20}}
                      onChange={useInputs}
                    />
                    <Button fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            type="submit">
                      Aceptar el servicio
                    </Button>
                  </Paper>
                </Grid>
              </form>:false}              
            </Grid>
          </Grid>
}

export default App
