import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Input from '../common/Input';
import StateContext from '../../helpers/ContextState';
import Camera from '../common/Camera';
import SendIcon from '@mui/icons-material/Send';
import Skeleton from '@mui/material/Skeleton';


const App=({label,id})=>{

  const token_relation        =   id
  const context               =   React.useContext(StateContext);
  const [inputs, setInputs]   =   useState({});
  const [desactivarInput, setDesactivarInput]   = useState(false);
  const [data, setData]       =   useState([]);
  const [loading, setLoading] =   useState(true);


  useEffect(() => {
    /*
      el envío de la solicitud es vía post porque el id puede ser un string con slash
      que el backend podría interpretar como url
    */
    context.post("api/comments",{id:token_relation},false,false).then((response)=>{
      if (response.data) {
        context.socket.emit('registerChat',{data:response.data,id:token_relation,user_id:context.user.id});
        setData(response.data)
        setLoading(false)
      }
    })

    context.socket.on('requestRegisterChat',(data)=>{
      if (data.room===token_relation && data.user_id!==parseInt(context.user.id) && data.data && data.data.data) {
        setData(data.data.data)
      }
    });


  }, [context,token_relation]);


  const onChange=(e)=>{
    if (desactivarInput) {
      return false;
    }
    let inputs_                 =   {...inputs}
        inputs_[e.target.name]  =   e.target.value
        setInputs(inputs_)
  }

  const onKeyPress=(e)=>{
    if (e.key === "Enter") {
      e.preventDefault()
      e.target.value=""
      send()
      setDesactivarInput(true);
    }
  }

  const send=()=>{
    if (!inputs.comment) {
      context.setSnackbars({
        show:true,
        message:"El mensaje no debe estar en blanco.",
      })
      return false;
    }
    context.post("api/comment/create",{...inputs,id:id},false,false).then((response)=>{
      setDesactivarInput(false);
      if (response && response.data) {
        context.socket.emit('registerChat',{data:response.data,id:token_relation,user_id:context.user.id});
        setData(response.data)
      }
    })
  }

  const openPreview=(url)=>{
    context.setModalShow({
      show:true,
      message:<img src={url} alt="Programandoweb"/>,
    })
  }

  return  <Grid container spacing={0}>
            <Grid item xs={12} className="chat-textarea mb-2">
              <Input  multiline
                      disabled={desactivarInput}
                      rows={3}
                      type="text"
                      id="comment"
                      className="m-0 bg-white"
                      label={label?label:"Mensaje... "}
                      name="comment"
                      onChange={onChange}
                      onKeyPress={onKeyPress}/>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Camera id={id} context={context} />
                  </Grid>
                  <Grid item xs={1}>
                    <SendIcon onClick={send} className="cursor-pointer"/>
                  </Grid>
                </Grid>
            </Grid>
            {!loading?<Grid item xs={12}  className="chat-content" >
              {data.length>0?<>
                {data.map((row,key)=>{
                  if (!row.users_name) {
                    return false
                  }
                  return    <Grid container className="chat-items" key={key}>
                              <Grid item xs={8} className="autor">{row.users_name} ({row.users_phone})</Grid>
                              <Grid item xs={4} className="date">{row.created_at_string}</Grid>
                              {row.file===''?<>
                                <Grid item xs={12} className="text text-justify">
                                  {row.comment}
                                </Grid>
                                </>:<>
                                  <Grid item xs={12} className="text">
                                    <img className="img-fluid" src={row.file} alt="Programandoweb" onClick={()=>openPreview(row.file)}/>
                                    {row.comment}
                                  </Grid>
                                </>
                              }
                            </Grid>

                })}
              </>:false}
            </Grid>:<>
              <Skeleton variant="rectangular" width={"100%"} height={10} sx={{mb:1,}}/>
              <Skeleton variant="rectangular" width={"100%"} height={10} sx={{mb:1,}}/>
              <Skeleton variant="rectangular" width={"100%"} height={10} sx={{mb:1,}}/>
            </>}

          </Grid>
}
export default App
