import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Select from '../Select';
import Input from '../Input';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {init,set} from '../../../helpers/fninputs';
import {useState} from 'react';
import Loading from '../Loading';

const App=({data,inputs,context,setOrder_details,setAddEquipament,equipment_id,setReload})=>{
  const [loading, setLoading]           =   useState(false);
  const [inputs2, setInputs2]           =   useState({});
  const [error, setError]               =   useState(false);

  init(inputs2, setInputs2)

  if (equipment_id) {
    setInputs2({...inputs2,equipment_id:equipment_id})
  }

  const onSubmit=()=>{
    if (  inputs2.equipment_id&&
          inputs2.subject&&
          inputs2.fulltext
          ) {
      setLoading(true)
      setError(false)
      context.post("api/orders/createItemOrder",{...inputs2,token:inputs.token,order_id:inputs.id},false,false).then((response)=>{
        if (response && response.data) {
          if (setOrder_details) {
            setOrder_details(response.data)
          }
          setLoading(false)
          context.setModalShow(false)
          setReload(true)
        }
      })
    }else {
      setError("Todos los datos son requeridos.")
    }

  }

  return  <>
            {loading?<Loading height="10vh" label="Guardando información..."/>:<>
              <Grid container spacing={0} >
                {!equipment_id?<Grid  item xs={12}>
                  <Select data={data}
                          name="equipment_id"
                          required={true}
                          byId={"id"}
                          defaultValue={inputs.equipment_id}
                          placeholder='Equipo para revisión'
                          inputs={inputs2}
                          setInputs={set}
                          label="Equipo para revisión"
                          value={inputs.equipment_id} />
                </Grid>:false}
                <Grid  item xs={12}>
                  <Input  margin="normal"
                          fullWidth
                          type="text"
                          id="subject"
                          label="Asunto del problema"
                          name="subject"
                          onChange={set}/>
                </Grid>
                <Grid  item xs={12}>
                  <Input  margin="normal"
                          fullWidth
                          multiline
                          rows={8}
                          type="text"
                          id="fulltext"
                          label="Descripción del problema"
                          name="fulltext"
                          onChange={set}/>
                </Grid>
                <Grid  item xs={12}>
                  {error?<Typography color="secondary">{error}</Typography>:false}
                  <Stack spacing={2} >
                    <Button variant="contained" color="secondary" onClick={onSubmit}>Agregar</Button>
                    <Button variant="contained" color="grey" onClick={()=>{setAddEquipament?setAddEquipament(false):context.setModalShow(false)}}>Cancelar</Button>
                  </Stack>
                </Grid>
              </Grid>
          </>}
        </>
}

export default App
