import React from 'react';
import Grid from '@mui/material/Grid';
//import Paper    from '@mui/material/Paper';
import Carousel    from './components/Carousel2';
import StateContext from '../helpers/ContextState';
import Typography from '@mui/material/Typography';
import Loading from '../components/common/Loading01';
import { useLocation } from "react-router-dom";
const parse = require('html-react-parser');

let getInit=false

const App=()=>{
  const location              =   useLocation();
  const context               =   React.useContext(StateContext);
  const [data,setData]        =   React.useState({})
  const [gallery, setGallery] =   React.useState([]);
  const [loading,setLoading]  =   React.useState(true)


  getInit=()=>{
    setData({})
    setLoading(true)
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        setLoading(false)
      }
      if (response.data && response.data.gallery) {
        setGallery(JSON.parse(response.data.gallery))
      }
    })
  }

  React.useEffect(()=>{
    getInit()
  },[location])

  // React.useEffect(()=>{
  //   getInit()
  // },[])

  return  <>
            <Carousel data={gallery}/>
            <Grid container justifyContent="center" spacing={2} sx={{mb:10}}>
              {
                !loading?<>
                  <Grid item xs={11} sx={{ mt: 8, }}>

                      <Typography variant="h4" component="b">
                        {data.component_id}
                      </Typography>
                      <Grid className="text-complete">
                        {
                          parse(data.content)
                        }
                      </Grid>

                  </Grid>
                </>:<>
                  <Loading/>
                </>
              }
            </Grid>
          </>
}
export default App;
