import * as React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


export default function ControlledSwitches({label,name,inputs,setInputs,data,row}) {

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    let inputs_                     =   {...inputs}
        if (!inputs_.multipleInputs) {
          inputs_.multipleInputs  =  {}
        }
        inputs_.multipleInputs[event.target.name]  =   event.target.checked
        setInputs(inputs_);
        setChecked(event.target.checked);
  };

  React.useEffect(()=>{
    const result  = data.find((search)=>search.permission_id===row.id)
    if (result) {
      setChecked(true)
    }else {
      setChecked(false)
    }
  },[data,row.id])

  React.useEffect(()=>{
    if (inputs.multipleInputs && inputs.multipleInputs[name]) {
      setChecked(inputs.multipleInputs[name])
    }
  },[inputs,name])


  return (
    <FormControlLabel control={
                                <Switch
                                        color="secondary"
                                        name={name}
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                } label={label} />

  );
}
