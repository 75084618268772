import React from 'react';
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import Loading from '../common/Loading01';
import CardUpload from '../common/CardUpload';
import Gallery from '../common/Gallery';
import StateContext from '../../helpers/ContextState';

let getInit=false

const App=()=>{

  const context               = React.useContext(StateContext);
  const [loading, setLoading] = React.useState(true);
  const [data, setData]       = React.useState([]);
  const [value, setValue]     = React.useState(false);

  React.useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=()=>{
    context.get(false,{},false,false).then((response)=>{
      setLoading(false)
      if (response && response.data) {
        setData(response.data)
      }
    })
  }

  const onClick=(row)=>{
    setValue(row)
  }

  return  <>{
              loading?<Loading height={'60vh'}/>:<Grid container justifyContent="center">
                    <Grid  item xs={11}>
                      <Grid container spacing={2}>
                        <Grid  item xs={12} md={3} >
                          <CardUpload setData={setData} value={value} setValue={setValue}/>
                        </Grid>
                        <Grid  item xs={12} md={9} >
                          <Gallery data={data} onClick={onClick}/>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
            }
          </>

}
export default App;
