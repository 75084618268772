import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Paper    from '@mui/material/Paper';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import StateContext from '../../helpers/ContextState';
import Select from '../common/Select';
import Loading from '../common/Loading01';
import Button from '@mui/material/Button';
import {init,set} from '../../helpers/fninputs';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import Textarea from '../common/TextareaCustom';
import ContentGallery from '../multimedia/ContentGallery';
import Carousel    from '../../web/components/Carousel';

const btnSubmitId    =   'btnSubmitId'
let getInit

const App=()=>{
  const navigate                              = useNavigate();
  const context                               = React.useContext(StateContext);
  const [loading, setLoading]                 = useState(true);
  const [inputs, setInputs]                   = useState({});
  const [categories, setCategories]           = useState({});
  const [gallery, setGallery]                 = useState([]);
  const [slider, setSlider]                   = useState([]);
  const [switch2, setSwitch]                   = useState(false);

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{

    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data && response.data.content) {
        setInputs(response.data)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()

    context.post(false,{  ...inputs,gallery:JSON.stringify(gallery),slider:JSON.stringify(slider)},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        navigate('/sections/edit/'+response.data.id)
      }
    })
  }

  const handleGallery=(event,filter)=>{
    context.setModalShow({
                            show:true,
                            message:  <Grid sx={{height:"80vh"}}>
                                        <ContentGallery event={event} context={context} filter={filter}/>
                                      </Grid>,
                            width:"90%"
                          }
                        )
  }

  const eventOne=(row)=>{
    gallery.push(row)
    setGallery(gallery)
  }

  const eventTwo=(row)=>{
    slider.push(row)
    setSlider(slider)
  }

  const onChange=(e)=>{
    e.preventDefault()
    let inputs_                 =   {...inputs}
        inputs_[e.target.name]  =   e.target.value
        setInputs(inputs_)
  }

  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={11} sm={11} md={11} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Paper sx={{ p: 2, }}>
                        <Grid container spacing={0} sx={{mt:8}}>
                          <Grid  item xs={12}>
                            <Grid sx={{mt:3}}>
                              <Button variant="contained" onClick={()=>setSwitch(switch2?false:true)}>Cambiar Textarea {switch2?"Simple":"Enriquecido"}</Button>
                            </Grid>
                            <Grid sx={{mt:3}}>
                              {switch2?<Textarea name="content" inputs={inputs} setInputs={setInputs}/>:<TextField
                                                                                                                  fullWidth
                                                                                                                  id="outlined-textarea"
                                                                                                                  label="Contenido"
                                                                                                                  placeholder="Contenido"
                                                                                                                  name="content"
                                                                                                                  onChange={onChange}
                                                                                                                  defaultValue={inputs.content}
                                                                                                                  multiline
                                                                                                                />}
                              </Grid>
                          </Grid>
                          <Grid  item xs={12}>
                            <Button fullWidth
                                    variant="contained"
                                    type="submit"
                                    sx={{ mt: 3, mb: 2 }}>Guardar</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
