import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Input    from '../Input';
import BtnSubmit from '../BtnSubmit';
import Select from '../Select';
import Loading from '../Loading';
import {init,set} from '../../../helpers/fninputs';
import {
          inputs,
          useInputs
        } from '../../../helpers/useInputs';

const btnSubmitId    =   'btnSubmitId'
let getInit

const App=({context,id,client_id,setReload})=>{
  //const [clients, setClients]   = useState([]);
  const [equipments_categories, setEquipments_categories]   = useState([]);
  const [equipments_marcas, setEquipments_marcas]   = useState([]);
  const [loading, setLoading]   = useState(true);
  const [inputs2, setInputs2]   = useState();
  init(inputs2, setInputs2)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{
    setLoading(true)
    context.get("api/equipment/add/0?client_id="+client_id,{...context.user},false,false).then((response)=>{
      if (response.data) {
        if (response.data.equipments_categories) {
          setEquipments_categories(response.data.equipments_categories)
        }
        // if (response.data.equipments_computer_internal_parts_categories) {
        //   setEquipments_computer_internal_parts_categories(response.data.equipments_computer_internal_parts_categories)
        // }
        if (response.data.equipments_marcas) {
          setEquipments_marcas(response.data.equipments_marcas)
        }
        // if (response.data.clients) {
        //   setClients(response.data.clients)
        // }

        delete(response.data.equipments_categories);
        delete(response.data.equipments_computer_internal_parts_categories);
        delete(response.data.equipments_marcas);
        delete(response.data.clients);
        setInputs2(response.data)
        setLoading(false)

      }
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);

  const onSubmit=(e)=>{
    e.preventDefault()
    context.post("api/equipment/add/0",{  ...inputs2,...inputs,client_id:client_id},false,true).then((response)=>{
      if (response && response.data && response.data.id) {
        context.setModalShow(false)
        setReload(true)
      }
    })
  }


  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={0}>
                  <Grid  item xs={11} sm={11} md={11} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>

                        <Grid container spacing={0}>
                          <Grid  item xs={12} sm={12} md={6}>
                            <Select data={equipments_marcas}
                                    name="equipments_marcas_id"
                                    byId={"id"}
                                    required={true}
                                    defaultValue={inputs2?inputs2.equipments_marcas_id:""}
                                    placeholder="Marca del equipo"
                                    inputs={inputs}
                                    setInputs={useInputs}
                                    label="Marca del equipo"
                                    value={inputs2.equipments_marcas_id} />
                          </Grid>
                          <Grid  item xs={12} sm={12} md={6}>
                            <Select data={equipments_categories}
                                    name="equipments_categories_id"
                                    byId={"id"}
                                    required={true}
                                    defaultValue={inputs2?inputs2.equipments_categories_id:""}
                                    placeholder="Categoría del equipo"
                                    inputs={inputs}
                                    setInputs={useInputs}
                                    label="Categoría del equipo"
                                    value={inputs2.equipments_categories_id} />
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={12} md={4}>
                            <Input  margin="normal"

                                    type="text"
                                    id="name"
                                    label="Identificación del equipo"
                                    name="name"
                                    defaultValue={inputs2.name}
                                    onChange={set}/>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <Input  margin="normal"

                                    type="text"
                                    id="equipment_fenix"
                                    label="Placa ID"
                                    name="equipment_fenix"
                                    defaultValue={inputs2.equipment_fenix}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} sm={12} md={4}>
                            <Input  margin="normal"

                                    type="text"
                                    id="equipment_serial"
                                    label="Serial del equipo"
                                    name="equipment_serial"
                                    autoFocus
                                    defaultValue={inputs2.equipment_serial}
                                    onChange={set}/>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid  item xs={12} sm={12} md={3}>
                            <Input  margin="normal"

                                    type="text"
                                    id="equipment_browser"
                                    label="Navegador del equipo"
                                    name="equipment_browser"
                                    autoFocus
                                    defaultValue={inputs2.equipment_browser}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} sm={12} md={3}>
                            <Input  margin="normal"

                                    type="text"
                                    id="equipment_name"
                                    label="Nombre del equipo"
                                    name="equipment_name"
                                    autoFocus
                                    defaultValue={inputs2.equipment_name}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} sm={12} md={3}>
                            <Input  margin="normal"

                                    type="text"
                                    id="equipment_username"
                                    label="Nombre de usuario en el equipo"
                                    name="equipment_username"
                                    autoFocus
                                    defaultValue={inputs2.equipment_username}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} sm={12} md={3}>
                            <Input  margin="normal"

                                    type="text"
                                    id="equipment_password"
                                    name="equipment_password"
                                    label="Password en el equipo"
                                    autoFocus
                                    defaultValue={inputs2.equipment_password}
                                    onChange={set}/>
                          </Grid>
                        </Grid>

                          <Input  margin="normal"
                                  fullWidth
                                  type="text"
                                  id="observation"
                                  label="Observación"
                                  name="observation"
                                  defaultValue={inputs2?inputs2.observation:""}
                                  onChange={set}/>

                      <Grid className="mt-2">
                        <Button type="submit" variant="contained" color="secondary" className="mr-2">Crear</Button>
                        <Button onClick={()=>{context.setModalShow(false)}} variant="contained" color="primary">Cancelar</Button>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
