import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";

import Grid from '@mui/material/Grid';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {modules,modules_menu,modules_web} from '../helpers/modules';
import { usePrivileges } from '../helpers/usePrivileges';

import MenuUser from './common/Menu';
import Menu from '../web/components/common/Menu';
import Breadcrumbs from './common/Breadcrumbs';
import Footer from '../web/components/common/Footer';



const menu_position = "fixed"



const App=({  onSubmit , setOnSubmit,user })=>{
  const navigate          = useNavigate();
  const [menus,setMenus]  =   React.useState(false)
  const location = useLocation();


  //context.setUser("close")
  React.useEffect(() => {
    if (  !location.pathname.includes("web") &&
          !location.pathname.includes("auth") &&
          location.pathname!=='' &&
          location.pathname!=='/' &&
          !user
        ) {
      setMenus(false)
      navigate("/auth")
    }else if ((location.pathname.includes("web")) || (location.pathname==='' || location.pathname==='/')) {
      setMenus("web")
    }else if (!location.pathname.includes("web") && location.pathname.includes("auth")) {
      setMenus(false)
    }else if (!location.pathname.includes("web") && !location.pathname.includes("auth")) {
      setMenus("admin")
    }else {
      setMenus(false)
    }
  }, [location,user,navigate]);


  return  <>
            {menus && menus==='admin'?<MenuUser user={user} position={menu_position}/>:menus && menus==='web'?<Menu user={user} position={menu_position}/>:false}
            {menu_position==='fixed' && menus?<Grid   sx={{ display: { xs: 'none' }, p: { xs: 'none',md:menus==='admin'?4:6 } }}/>:false}
            {
              menus && menus==='admin'?<Breadcrumbs usePrivileges={usePrivileges} onSubmit={onSubmit} setOnSubmit={setOnSubmit}/>:false
            }
            <Routes>
              {modules.map((row,key)=>{
                if (!row.items) {
                  return  <Route key={key} path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                }else {
                  return row.items.map((row2,key2)=>{
                    if (key2===0) {
                      return  <>
                                <Route key={key}  path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                                <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                              </>
                    }else if(Array.isArray(row2.slug)){
                      return  row2.slug.map((row3,key3)=>{
                        return <Route key={key3} path={row2.slug[key3]} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                      })
                    }else {
                      return <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                    }
                  })
                }

              })}
              {modules_menu.map((row,key)=>{
                return  <Route key={key} path={row.slug} element={<>{<row.component/>}</>}/>
              })}
              {modules_web.map((row,key)=>{
                if (!row.items) {
                  return  <Route key={key} path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                }else {
                  return row.items.map((row2,key2)=>{
                    if (key2===0) {
                      return  <>
                                <Route key={key}  path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                                <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                              </>
                    }else if(Array.isArray(row2.slug)){
                      return  row2.slug.map((row3,key3)=>{
                        return <Route key={key3} path={row2.slug[key3]} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                      })
                    }else {
                      return <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                    }
                  })
                }
              })}
              <Route path="*" element={<>No existe</>} />
            </Routes>
            <Footer/>
          </>
}

export default App
