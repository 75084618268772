import * as axios from 'axios';
//import { Typography } from '@mui/material';

let functions     = {}

export const IsValidJSONString=(item)=>{
    item = typeof item !== "string"? JSON.stringify(item): item;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === "object" && item !== null) {
      return true;
    }
    return false;
}

export const setFunctions=(functions_)=>{
  functions = {...functions,...functions_}
}

const processData=(inputs)=>{
  let data          = new FormData();
      data.append("pathname",window.location.pathname)
      if (functions.csrf_token) {
        data.append("csrf_token",functions.csrf_token)
      }
      if (functions.user && functions.user.access_token) {
        data.append("access_token",functions.user.access_token)
      }
      if (functions.token) {
        data.append("access_token",functions.token)
      }
      if (inputs) {
        Object.entries(inputs).map((v,k) => {
          return data.append (v[0],v[1]);
        })
      }
      if (functions && functions.position) {
        Object.entries(functions.position).map((row,key)=>{
          if (row[1]!=='') {
            return data.append (row[0],row[1]);
          }else {
            return false

          }
        })
      }
  return data;
}

export  const  getRemote  =   async (endpoint,inputs,loading,modalShow)=>{

  try {

    return fetch('https://crossorigin.me/'+endpoint).then(d => d.text()).then(d => console.log(d))


    //console.log(`bearer ${functions.store.get("user").access_token}`);
    //axios.defaults.headers.common['Authorization'] = `Bearer ${functions.store.get("user").access_token}`;
    //axios.defaults.headers.common['Accept'] = 'application/json';
    // axios.defaults.headers.common['User-Agent'] = 'PostmanRuntime/7.29.2';
    // axios.defaults.headers.common['Accept'] = '*/*';
    // axios.defaults.headers.common['Accept-Encoding']  = 'gzip, deflate, br';
    // axios.defaults.headers.common['Connection']       = 'keep-alive';
    // axios.defaults.headers.common['Host']             = 'bodytech.com.co';
    // axios.defaults.headers.common['Referer']          = 'https://bodytech.com.co/ofertas?utm_source=google&utm_medium=elite&utm_campaign=body&gclid=Cj0KCQjwuaiXBhCCARIsAKZLt3nUcDMTxUkP8lCS2uaaQNzmkyfaFVfDW3B-Fd0XHT2tfj2euSsudXAaAtWPEALw_wcB';
    // axios.defaults.withCredentials = false;

    //var content   =   "Hello World";
    var myHeaders =   new Headers();
        // myHeaders.append("Accept", "*/*");
        // myHeaders.append("Cookie", "_gcl_aw=GCL.1659533242.Cj0KCQjwuaiXBhCCARIsAKZLt3nUcDMTxUkP8lCS2uaaQNzmkyfaFVfDW3B-Fd0XHT2tfj2euSsudXAaAtWPEALw_wcB; _gcl_au=1.1.1045870458.1659500344; cto_bundle=-OCmzV9KNDlJcDJuYXd2bzVMUHdQOFFMbE8yQ2NXZVA5VTUlMkJQQXZkeW91TW9UM0NCdHZCbmt6RFAzY3h2Rnd0WHMxQTE1bEZUaWZwb2hYQVZVWWNlUzZlOTl5R0JzY1hFJTJCdGdmUW5LbEtxWDM4Q1JZcFpsQzhvUmZmNFM2ZHpHd3EweEgxUWF3byUyQkxJJTJCZ0RjNGZUWDYza2NNdyUzRCUzRA; _ga=GA1.3.8382800.1659500348; _gid=GA1.3.952413467.1659500348; _gac_UA-54919237-1=1.1659532697.Cj0KCQjwuaiXBhCCARIsAKZLt3nUcDMTxUkP8lCS2uaaQNzmkyfaFVfDW3B-Fd0XHT2tfj2euSsudXAaAtWPEALw_wcB; _fbp=fb.2.1659500348553.1695196896; _hjSessionUser_2685843=eyJpZCI6IjgyOTcwMzk4LTgyOGUtNTRlYS1hNTkzLWRlMDA5ZjU1ZTI0OCIsImNyZWF0ZWQiOjE2NTk1MDAzNDg2NTcsImV4aXN0aW5nIjp0cnVlfQ==; _hjIncludedInSessionSample=0; _hjSession_2685843=eyJpZCI6ImJiMjhmNGZiLTFkZjYtNGEyMS1iYzRhLTVhMTY1ZjE3YThlYiIsImNyZWF0ZWQiOjE2NTk1MzI2OTU2ODEsImluU2FtcGxlIjpmYWxzZX0=; _hjIncludedInPageviewSample=1; _hjAbsoluteSessionInProgress=0");
        //
        // myHeaders.append("If-None-Match", "d86-Nb950F2xsoJ/XuJqVfTPELiuZtM");
        // myHeaders.append("Referer", 'https://bodytech.com.co/ofertas?utm_source=google&utm_medium=elite&utm_campaign=body&gclid=Cj0KCQjwuaiXBhCCARIsAKZLt3nUcDMTxUkP8lCS2uaaQNzmkyfaFVfDW3B-Fd0XHT2tfj2euSsudXAaAtWPEALw_wcB');
        // myHeaders.append("Sec-Fetch-Dest", 'empty');
        // myHeaders.append("Sec-Fetch-Mode", 'cors');
        // myHeaders.append("Sec-Fetch-Site", 'same-origin');
        // myHeaders.append("Postman-Token", '<calculated when request is sent>');
        // myHeaders.append("User-Agent", 'PostmanRuntime/7.29.2');

        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");



    const response    =   await fetch(endpoint,{
        method: 'GET',
        mode: 'cors',
        headers:myHeaders,
        // headers: {
        //   //'Content-Type': 'application/json'
        //   'Referer':'https://bodytech.com.co/ofertas?utm_source=google&utm_medium=elite&utm_campaign=body&gclid=Cj0KCQjwuaiXBhCCARIsAKZLt3nUcDMTxUkP8lCS2uaaQNzmkyfaFVfDW3B-Fd0XHT2tfj2euSsudXAaAtWPEALw_wcB',
        //   'Cookie':'_gcl_aw=GCL.1659533242.Cj0KCQjwuaiXBhCCARIsAKZLt3nUcDMTxUkP8lCS2uaaQNzmkyfaFVfDW3B-Fd0XHT2tfj2euSsudXAaAtWPEALw_wcB; _gcl_au=1.1.1045870458.1659500344; cto_bundle=-OCmzV9KNDlJcDJuYXd2bzVMUHdQOFFMbE8yQ2NXZVA5VTUlMkJQQXZkeW91TW9UM0NCdHZCbmt6RFAzY3h2Rnd0WHMxQTE1bEZUaWZwb2hYQVZVWWNlUzZlOTl5R0JzY1hFJTJCdGdmUW5LbEtxWDM4Q1JZcFpsQzhvUmZmNFM2ZHpHd3EweEgxUWF3byUyQkxJJTJCZ0RjNGZUWDYza2NNdyUzRCUzRA; _ga=GA1.3.8382800.1659500348; _gid=GA1.3.952413467.1659500348; _gac_UA-54919237-1=1.1659532697.Cj0KCQjwuaiXBhCCARIsAKZLt3nUcDMTxUkP8lCS2uaaQNzmkyfaFVfDW3B-Fd0XHT2tfj2euSsudXAaAtWPEALw_wcB; _fbp=fb.2.1659500348553.1695196896; _hjSessionUser_2685843=eyJpZCI6IjgyOTcwMzk4LTgyOGUtNTRlYS1hNTkzLWRlMDA5ZjU1ZTI0OCIsImNyZWF0ZWQiOjE2NTk1MDAzNDg2NTcsImV4aXN0aW5nIjp0cnVlfQ==; _hjIncludedInSessionSample=0; _hjSession_2685843=eyJpZCI6ImJiMjhmNGZiLTFkZjYtNGEyMS1iYzRhLTVhMTY1ZjE3YThlYiIsImNyZWF0ZWQiOjE2NTk1MzI2OTU2ODEsImluU2FtcGxlIjpmYWxzZX0=; _hjIncludedInPageviewSample=1; _hjAbsoluteSessionInProgress=0'
        //
        // },

    });

    // const response    =   await axios.get(endpoint,{
    //     params: {
    //         name: 'Sherlock',
    //         enemy: 'Moriarty'
    //     }
    // });

    return console.log(response.data);

    // var myAxios = axios.create({
    //   baseURL: process.env.REACT_APP_BACKEND+endpoint,
    //   timeout: 700,
    //   headers: {'Authorization': `bearer ${functions.store.get("user").access_token}`}
    // });
    //
    // const response    =   await myAxios.get(process.env.REACT_APP_BACKEND+endpoint);

    if (!IsValidJSONString(response.data)) {
      if (modalShow && functions.setModalShow) {
        functions.setModalShow({
                        show:true,
                        message:"Error en formato JSON, consulte backend",
                        size:""
                      }
                    )
      }
      return false;
    }

    if (modalShow && functions.setModalShow) {
      console.log(response.data);
    }

    functions.setOpenMessage(false)
    functions.localDatabase.requestApi(endpoint,response.data)
    return response.data;

  } catch (e) {
    if (functions.setModalShow && e.message==='Network Error') {
      functions.internetStatus(e)
      let promise = new Promise((resolve, reject) => {
        functions.localDatabase.responseApi(endpoint).then((data)=>{
          resolve(data.response);
        })
      });
      return  await promise
    }else {
      if (modalShow && functions.setModalShow) {
        functions.setModalShow({
                        show:true,
                        message:e.message,
                        size:""
                      }
                    )
      }
      return e
    }

  } finally {
    if (loading && functions.setLoading) {
      functions.setLoading(false)
    }
  }

}

/*
  parametros:
    endpoint:   si viene vacío lo lleno con la url actual
    inputs:     data a enviar, si viene vacío se ignora
    loading:    (bool)  dispara una vista de loading
    modalShow:  (bool)  es el state en app.js que enciende y apaga el loading
*/

export  const  get  =   async (endpoint,inputs,loading,modalShow)=>{

  if (!endpoint) {
    endpoint        =   "api"+window.location.pathname;
  }

  /*
    state que controla un ícono de loading
    parámetro loading true dispara este evento al inicio
  */

  if (loading && functions.setLoading) {
    functions.setLoading(true)
  }

  try {

    //console.log(`bearer ${functions.store.get("user").access_token}`);
    axios.defaults.headers.common['Authorization'] = `Bearer ${functions.store.get("user").access_token}`;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.withCredentials = false;

    const response    =   await axios.get(process.env.REACT_APP_BACKEND+endpoint);


    // var myAxios = axios.create({
    //   baseURL: process.env.REACT_APP_BACKEND+endpoint,
    //   timeout: 700,
    //   headers: {'Authorization': `bearer ${functions.store.get("user").access_token}`}
    // });
    //
    // const response    =   await myAxios.get(process.env.REACT_APP_BACKEND+endpoint);

    if (!IsValidJSONString(response.data)) {
      if (modalShow && functions.setModalShow) {
        functions.setModalShow({
                        show:true,
                        message:"Error en formato JSON, consulte backend",
                        size:""
                      }
                    )
      }
      return false;
    }

    if (modalShow && functions.setModalShow) {
      console.log(response.data);
    }

    functions.setOpenMessage(false)
    functions.localDatabase.requestApi(endpoint,response.data)
    return response.data;

  } catch (e) {
    if (functions.setModalShow && e.message==='Network Error') {
      functions.internetStatus(e)
      let promise = new Promise((resolve, reject) => {
        functions.localDatabase.responseApi(endpoint).then((data)=>{
          resolve(data.response);
        })
      });
      return  await promise
    }else {
      if (modalShow && functions.setModalShow) {
        functions.setModalShow({
                        show:true,
                        message:e.message,
                        size:""
                      }
                    )
      }
      return e
    }

  } finally {
    if (loading && functions.setLoading) {
      functions.setLoading(false)
    }
  }

}

/*
  parametros:
    endpoint:   si viene vacío lo lleno con la url actual
    inputs:     data a enviar, si viene vacío se ignora
    loading:    dispara una vista de loading
    functions.setLoading: es el state en app.js que enciende y apaga el loading
*/

export  const  post =   async (endpoint,inputs,loading,modalShow)=>{

  if (!endpoint) {
    endpoint        =   "api"+window.location.pathname;
  }

  let send          =   processData(inputs)
  let split         =   (functions.store && functions.store.get("user") && functions.store.get("user").access_token!==undefined)?functions.store.get("user").access_token.split("|")[1]:"";

  let header        =   {
                            headers: {
                              'Accept': 'application/json, text/plain, */*',
                              'Content-Type': 'application/json',
                              "X-Requested-With": "XMLHttpRequest",
                              "Authorization" : "Bearer "+split,
                              //'X-CSRF-TOKEN': '{{ csrf_token() }}'
                              'X-CSRF-TOKEN': functions.csrf_token,
                            }
                          }



  /*
    state que controla un ícono de loading
    parámetro loading true dispara este evento al inicio
  */

  if (loading && functions.setLoading) {
    functions.setLoading(true)
  }

  try {
    const response    =   await axios.post(process.env.REACT_APP_BACKEND+endpoint, send, header);

    if (!IsValidJSONString(response.data)) {
      if (modalShow && functions.setModalShow) {
        functions.setModalShow({
                        show:true,
                        message:"Error en formato JSON, consulte backend",
                        size:""
                      }
                    )
      }
      return false;
    }


    if (  modalShow &&
          functions.setModalShow &&
          response.data.code==='SUCCESS' &&
          response.data.message) {
          functions.setModalShow({
                                    show:true,
                                    title:response.data.code,
                                    message:<div>{response.data.message}</div>,
                                    size:""
                                  }
                                )
          setTimeout(function(){
            functions.setModalShow(false)
          }, 4000);
    }
    functions.setOpenMessage(false)
    functions.localDatabase.requestApi(endpoint,response.data)
    return response.data;

  } catch (e) {
    if (e.message==='Network Error') {
      functions.localDatabase.responseApi(endpoint).then((data)=>{
        return data.response.data;
      })
      functions.internetStatus(e)
    }
    if (modalShow && functions.setModalShow) {
      if (e.response &&
          e.response.data &&
          e.response.data.code &&
          e.response.data.message ) {
        return functions.setModalShow({
                                          show:true,
                                          title:e.response.data.code,
                                          message:<div>{e.response.data.message}</div>,
                                          size:""
                                        }
                                      )
      }else if (e.response && e.response.statusText==='Unauthorized') {
        functions.setModalShow({
                          show:true,
                          title:e.response.status,
                          message:<div>{e.response.statusText}</div>,
                          size:""
                      }
                    )
      }else if (e.response && e.response.statusText==='0') {
        functions.setModalShow({
                          show:true,
                          title:"Error",
                          message:<div>{"¡Estamos presentando inconvenientes de conexión!"}</div>,
                          size:""
                      }
                    )
      }
      // else {
      //   functions.setModalShow({
      //                   show:true,
      //                   message:  <>
      //                               <div>{e.response.status}</div>
      //                               <div>22</div>
      //                             </>,
      //                   size:""
      //                 }
      //               )
      // }
    }

    return e
  } finally {
    if (loading && functions.setLoading) {
      functions.setLoading(false)
    }
  }
}

/*
  parametros:
    endpoint:   si viene vacío lo lleno con la url actual
    inputs:     data a enviar, si viene vacío se ignora
    loading:    dispara una vista de loading
    functions.setLoading: es el state en app.js que enciende y apaga el loading
*/

export  const  put  =   async (endpoint,inputs,loading,modalShow)=>{
  //let send = processData(inputs)
}

/*
  parametros:
    endpoint:   si viene vacío lo lleno con la url actual
    inputs:     data a enviar, si viene vacío se ignora
    loading:    dispara una vista de loading
    functions.setLoading: es el state en app.js que enciende y apaga el loading
*/

export  const  del  =   async (endpoint,inputs,loading,modalShow)=>{
  // let send = processData(inputs)
  // try {
  //   const response    =   await axios.delete(process.env.REACT_APP_BACKEND+endpoint, send);
  // } catch (e) {
  //
  // } finally {
  //
  // }
}
