import * as React from 'react';
import StateContext from '../../../helpers/ContextState';
import OrdersAdd from './OrdersAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const App=({id,client_id,setReload})=>{
  const context  = React.useContext(StateContext);
  const handleOnClick=()=>{
    return context.setModalShow({
                    show:true,
                    title:"Gestionar",
                    message:<OrdersAdd setReload={setReload} client_id={client_id} context={context} id={id}/>,
                    width:1000
                  }
                )
  }
  return  <>
            <AddCircleOutlineIcon onClick={handleOnClick} className="ml-2 cursor-pointer"/>
          </>
}
export default App
