import {modules} from './modules';
import { useLocation } from "react-router-dom";
let   result      = false
let   result1     = false
let   result2     = false
let   component   = false
export const usePrivileges=()=>{
  const location  = useLocation();
  //console.log(location,modules);
  result      =   modules.find((search)=>search.slug===location.pathname || (search.items && search.items.find((search2)=>search2.slug===location.pathname)));
  if (result && result.items) {
    result1    =   result.items.find((search)=>location.pathname===search.slug || search.slug.includes(location.pathname))
  }

  result2      =   modules.find((search)=>search.arraySearch);
  /*busco por arraySearch los modulos relacionados, si consigo resultados al final seteo result1*/
  if (result2 && result2.items) {
    let split   =   location.pathname.split("/");
    let slug    =   "/"+split[1]+"/"+split[2];
    result2     =   result2.items.find(search=>search.slug.includes(slug))
  }

  /*busco cortando la url*/
  if (!result) {
    location.pathname.split("/").map((row,key)=>{
      if (!component&&row!=='') {
        component=row
      }
      if (component) {
        result            =  modules.find((search)=>search.slug.includes(component))
        if (result) {
          return result1    =   result.items.find((search)=>location.pathname.includes(search.method))
        }else {
          return result1    =   []
        }
      }
      return false
    })

  }
  if (!result1 && result2) {
    result1 = result2
  }
  return {primary:result,secondary:result1}
}
