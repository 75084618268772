import Home from '../components/home';


import WebEmpty from '../web/Empty';
import Main from '../web/Main';
import WebLogin from '../components/auth/index';

import FormBasic from '../web/components/forms/FormBasic';
import FormAfiliateExist from '../web/components/forms/FormAfiliateExist';
import UsersArea from '../components/UsersArea';


//import UserProfile from '../components/user/UserProfile';
//import UserAccount from '../components/user/UserAccount';
import UserExit from '../components/user/UserExit';
import UsersGet from '../components/user/UsersGet';


import ClientsGet from '../components/client/ClientGet';
import ClientView from '../components/client/ClientView';

import WebCMS from '../web/WebCMS';


/*multimedia*/
import MultimediaList from '../components/multimedia/Content';
import ScrapingList from '../components/scraping/ScrapingList';
import WebScraping from '../components/scraping/Desing';

import BannersList from '../components/multimedia/BannersList';
import BannersEdit from '../components/multimedia/BannersEdit';



/*supports*/
//import SupportsGet from '../components/support/SupportsGet';
//import SupportsGetOpen from '../components/support/SupportsGetOpen';
//import SupportsGetClose from '../components/support/SupportsGetOpen';

/*contetnt*/
import ContentsList from '../components/content/list';
import SectionsList from '../components/content/SectionsList';
import ContentsAdd from '../components/content/ContentsAdd';
import SectionsAdd from '../components/content/SectionsAdd';
import EstructuraOrganizacional from '../components/content/EstructuraOrganizacional';
import EstructuraOrganizacionalAdd from '../components/content/EstructuraOrganizacionalAdd';

/*menu*/
import MenuList from '../components/menu/list';
import MenuAdd from '../components/menu/MenuAdd';

/*orders*/
import OrdersList from '../components/orders/list';

// import OrdersAdd from '../components/orders/OrdersAdd';
// import OrdersListOpen from '../components/orders/list';
// import OrdersListClose from '../components/orders/list';
// import OrdersByUser from '../components/orders/OrdersByUser';
// import ViewByUser from '../components/orders/ViewByUser';



/*equipments*/
// import EquipmentGet from '../components/equipment/EquipmentGet';
// import EquipmentAdd from '../components/equipment/EquipmentAdd';
//import EquipmentView from '../components/equipment/EquipmentAdd';
//import EquipmentEdit from '../components/equipment/EquipmentEdit';



/*calendar*/
//import CalendarGet from '../components/calendar/CalendarGet';


//import ClientGet from '../components/client/ClientGet';
import ClientAdd from '../components/client/ClientAdd';



/*users*/
//import Geolocation from '../components/user/Geolocation';
import UserView from '../components/user/UserView';
import UserAdd from '../components/user/UserAdd';


/*setting*/
//import SettingGet from '../components/setting/SettingGet';
import SettingRoles from '../components/setting/SettingRoles';
import SettingRolEdit from '../components/setting/SettingRolEdit';
import SettingPermission from '../components/setting/SettingPermission';
import SettingPermissionEdit from '../components/setting/SettingPermissionEdit';

// import SettingCategoriesEquipments from '../components/setting/SettingCategoriesEquipments';
// import SettingCategoriesPeripherals from '../components/setting/SettingCategoriesPeripherals';
// import SettingCategoriesComputerInternalParts from '../components/setting/SettingCategoriesComputerInternalParts';
// import AddCategoriesPeripherals from '../components/setting/AddCategoriesPeripherals';
// import AddCategoriesEquipments from '../components/setting/AddCategoriesEquipments';
// import AddComputerInternalParts from '../components/setting/AddComputerInternalParts';


/*freeZone*/
import FreezoneViewbyuser from '../components/freezone/FreezoneViewbyuser';


/*chatbot config*/

//import ChatBot from '../components/chatbot/index.jsx';


/*setIcons*/
//import HomeIcon from '@mui/icons-material/Home';

export const modules_web  = [
  {
    name:"Home",
    slug:"/",
    module:"web",
    method:"home",
    component:UsersArea,
    className:"btn btn-white",
    menu:false,
  },
  {
    name:"Home",
    slug:"/web",
    module:"web",
    method:"home",
    component:Main,
    className:"btn btn-white",
    menu:false,
  },
  {
    name:"Auth",
    slug:"/auth/*",
    module:"web",
    method:"home",
    component:WebLogin,
    className:"btn btn-white",
    menu:false,
  },
  {
    name:"Login",
    slug:"/login",
    module:"web",
    method:"home",
    component:WebLogin,
    className:"btn btn-white",
    menu:false,
  },
  {
    name:"Consulta tu cuenta",
    slug:"/web/consulta-tu-cuenta",
    module:"web",
    method:"home",
    component:WebEmpty,
    className:"btn btn-white",
    menu:true,
  },
  {
    name:"Simulador de crédito",
    slug:"/web/simulador-credito",
    module:"web",
    method:"home",
    component:WebEmpty,
    className:"btn btn-white",
    menu:true,
  },
  {
    name:"Nosotros",
    slug:"/web/nosotros",
    module:"web",
    method:"us",
    component:WebEmpty,
    menu:true,
    items:[
      {
        name:"CMS",
        slug:"/web/cms/:slug",
        module:"web",
        method:"cms",
        component:WebCMS,
        menu:false,
      },
      {
        name:"¿Cómo asociarte?",
        slug:"/web/cms/como-asociarte",
        module:"web",
        method:"cms",
        component:WebEmpty,
        menu:true,
      },
      {
        name:"Afíliate aquí",
        slug:"/web/formulario/afiliate",
        module:"web",
        method:"form",
        component:FormBasic,
        menu:true,
      },
      {
        name:"Afíliate aquí",
        slug:"/web/formulario/afiliate_exist",
        module:"web",
        method:"form",
        component:FormAfiliateExist,
        menu:false,
      },
      {
        name:"Afíliate aquí",
        slug:"/web/formulario/afiliate/:id",
        module:"web",
        method:"form",
        component:FormBasic,
        menu:false,
      },
    ]
  },
  {
    name:"Servicios",
    slug:"/web/servicios",
    module:"web",
    method:"services",
    component:WebEmpty,
    menu:true,
  },
  {
    name:"Beneficios",
    slug:"/beneficios",
    module:"scraping",
    method:"scraping",
    component:WebScraping,
    menu:false,
  },
  {
    name:"Noticias y eventos",
    slug:"/web/noticias-y-eventos",
    module:"web",
    method:"news",
    component:WebEmpty,
    menu:true,
  },
  {
    name:"Contacto",
    slug:"/web/contacto",
    module:"web",
    method:"contact",
    component:WebEmpty,
    menu:true,
  },
  {
    name:"Área de usuarios",
    slug:"/user",
    module:"web",
    method:"services",
    component:UsersArea,
    menu:true,
  },
]

export const modules_freezone  = [
  {
    name:"Freezone",
    slug:"/open/orders/viewbyuser/:id",
    module:"orders",
    method:"viewbyuser",
    component:FreezoneViewbyuser,
  }
]

export const modules  = [
  // {
  //   name:"home",
  //   slug:"/",
  //   module:"home",
  //   method:"init",
  //   component:Home,
  //   menu:true,
  //   //Icon:HomeIcon
  // },
  {
      name:"user",
      slug:"/user",
      module:"home",
      method:"init",
      component:Home,
      menu:false,
    },{
    name:"Usuarios",
    slug:"/users",
    module:"users",
    method:"get",
    component:ClientsGet,
    pdf:false,
    excel:false,
    add:"/users/add/0",
    menu:true,
    items:[
      // {
      //   name:"Listado",
      //   slug:"/users/list",
      //   module:"users",
      //   method:"get",
      //   component:UsersGet,
      //   pdf:false,
      //   excel:false,
      //   add:"/users/add/0",
      //   edit:"/users/edit",
      //   view:"/users/view",
      //   activity:"/orders/byuser",
      //   remove:"/users/remove",
      //   menu:true,
      // },
      {
        name:"Clientes",
        slug:"/clients/list",
        module:"clients",
        method:"get",
        component:ClientsGet,
        view:"/clients/view",
        pdf:false,
        excel:false,
        menu:true,
      },
      {
        name:"Cliente descripción",
        slug:"/clients/view/:id",
        module:"clients",
        method:"get",
        component:ClientView,
        menu:false,
      },
      {
        name:"Listado",
        slug:"/users/list/:id",
        module:"users",
        method:"get",
        component:UsersGet,
        pdf:true,
        excel:true,
        add:"/users/add/0",
        edit:"/users/edit",
        view:"/users/view",
        activity:"/orders/byuser",
        remove:"/users/remove",
        menu:false,
      },
      // {
      //   name:"Geolocalización",
      //   slug:"/users/geolocation",
      //   module:"users",
      //   method:"get",
      //   component:Geolocation,
      //   pdf:false,
      //   excel:false,
      //   menu:true,
      // },
      {
        name:"Agregar clientes",
        slug:"/users/add/0",
        module:"users",
        method:"add",
        component:ClientAdd,
        parent:"/users/list",
        menu:false,
      },
      {
        name:"Editar clientes",
        slug:"/users/edit/:id",
        module:"users",
        method:"edit",
        component:UserAdd,
        parent:"/users/list",
        pdf:false,
        excel:false,
        menu:false,
      },
      {
        name:"Ver Usuario",
        slug:"/users/view/:id",
        module:"users",
        method:"view",
        component:UserView,
        parent:"/users/list",
        view:"/equipment/view",
        activity:"/orders/view",
        pdf:false,
        excel:false,
        menu:false,
      },
    ]
  },{
    name:"Menú",
    slug:"/menu",
    module:"menu",
    method:"get",
    component:MenuList,
    add:"/menu/add/0",
    edit:"/menu/edit",
    view:"/menu/add",
    remove:"/menu/remove",
    arraySearch:["/menu/add","/menu/add/:id",],
    menu:true,
    items:[
      {
        name:"Gestion",
        slug:"/menu",
        module:"menu",
        method:"get",
        component:MenuList,
        add:"/menu/add/0",
        edit:"/menu/edit",
        view:"/menu/add",
        remove:"/menu/remove",
        menu:true,
      },
      {
        name:"Editar",
        slug:"/menu/add/:id",
        module:"menu",
        method:"get",
        component:MenuAdd,
        add:"/menu/add/0",
        edit:"/menu/edit",
        view:"/menu/add",
        remove:"/menu/remove",
        menu:false,
      },
    ]
  },{
    name:"Contenido",
    slug:"/contenido",
    module:"contents",
    method:"get",
    component:OrdersList,
    add:"/contents/add/0",
    edit:"/contents/edit",
    view:"/contents/add",
    activity:"/contents/addbyuser",
    remove:"/contents/remove",
    arraySearch:["/contents/add","/contents/byuser",],
    menu:true,
    items:[
      {
        name:"Estructura organizacional",
        slug:"/estructura_organizacional",
        module:"sections",
        method:"get",
        component:EstructuraOrganizacional,
        add:"/sections/add/0",
        edit:"/sections/edit",
        view:"/sections/add",
        remove:"/sections/remove",
        menu:false,
      },
      {
        name:"Listado estructura_organizacional",
        slug:"/estructura_organizacional/add/:id",
        module:"users",
        method:"get",
        component:EstructuraOrganizacionalAdd,
        menu:false,
      },
      {
        name:"Secciones",
        slug:"/sections",
        module:"sections",
        method:"get",
        component:SectionsList,
        add:"/sections/add/0",
        edit:"/sections/edit",
        view:"/sections/add",
        remove:"/sections/remove",
        menu:true,
      },
      {
        name:"Secciones",
        slug:"/sections/edit/:id",
        module:"sections",
        method:"get",
        component:SectionsAdd,
        add:"/sections/add/0",
        edit:"/sections/edit",
        view:"/sections/add",
        remove:"/sections/remove",
        menu:false,
      },
      {
        name:"Gestion",
        slug:"/contents",
        module:"contents",
        method:"get",
        component:ContentsList,
        add:"/contents/add/0",
        edit:"/contents/edit",
        view:"/contents/add",
        remove:"/contents/remove",
        menu:false,
      },
      {
        name:"Gestion",
        slug:"/contents/add/:id",
        module:"contents",
        method:"get",
        component:ContentsAdd,
        add:"/contents/add/0",
        edit:"/contents/edit",
        view:"/contents/add",
        remove:"/contents/remove",
        menu:false,
      },
      {
        name:"Agregar Categorías",
        slug:"/contents/categories",
        module:"contents",
        method:"getCategories",
        component:OrdersList,
        add:"/contents/categories/add/0",
        edit:"/contents/categories/edit",
        view:"/contents/categories/view",
        remove:"/contents/categories/remove",
        menu:false,
      },
    ]
  },{
    name:"Multimedia",
    slug:"/multimedia",
    module:"multimedia",
    method:"get",
    component:MultimediaList,
    menu:true,
    items:[
      {
        name:"Banners",
        slug:"/banners",
        module:"banners",
        method:"get",
        component:BannersList,
        menu:true,
      },
      {
        name:"Agregar Banners",
        slug:"/banners/add/0",
        module:"banners",
        method:"addBanners",
        component:BannersEdit,
        parent:"/setting/roles",
        pdf:false,
        excel:false,
        menu:false,
      },
      {
        name:"Gestion",
        slug:"/multimedia",
        module:"multimedia",
        method:"get",
        component:MultimediaList,
        menu:true,
      },
      {
        name:"Scraping",
        slug:"/scraping/gym",
        module:"scraping",
        method:"get",
        component:ScrapingList,
        menu:true,
      },
    ]
  },{
    name:"Configuración",
    slug:"/setting",
    module:"setting",
    method:"get",
    component:SettingRoles,
    add:"any",
    edit:"any",
    view:"any",
    remove:"any",
    menu:false,
    items:[
      {
        name:"Roles",
        slug:"/setting/roles",
        module:"setting",
        method:"roles",
        component:SettingRoles,
        pdf:false,
        excel:false,
        add:"/setting/addRoles/0",
        edit:"/setting/editRoles",
        view:"/setting/viewRoles",
        menu:false,
      },
      {
        name:"Permisos",
        slug:"/setting/permission",
        module:"setting",
        method:"permission",
        component:SettingPermission,
        pdf:false,
        excel:false,
        // add:"/setting/addPermissionEdit/0",
        // edit:"/setting/editPermissionEdit",
        // view:"/setting/viewPermissionEdit",
        menu:false,
      },

      {
        name:"Editar Permisos",
        slug:["/setting/editPermissionEdit/:id","/setting/addPermissionEdit/:id"],
        module:"setting",
        method:"editPermission",
        component:SettingPermissionEdit,
        parent:"/setting/permission",
        pdf:false,
        excel:false,
        menu:false,
      },

      {
        name:"Agregar Rol de Usuario",
        slug:"/setting/addRoles/0",
        module:"setting",
        method:"addRoles",
        component:SettingRolEdit,
        parent:"/setting/roles",
        pdf:false,
        excel:false,
        menu:false,
      },
      {
        name:"Editar Rol de Usuario",
        slug:"/setting/editRoles/:id",
        module:"setting",
        method:"editRoles",
        component:SettingRolEdit,
        parent:"/setting/roles",
        pdf:false,
        excel:false,
        menu:false,
      },
    ]
  }
]

export const modules_menu  = [
  {
    name:"Area restringida",
    slug:"/users",
    module:"users",
    method:"get",
    component:ClientsGet,
    pdf:false,
    excel:false,
    add:"/users/add/0",
    menu:true,
  },
  {
    name:"Area restringida",
    slug:"/users/:id",
    module:"users",
    method:"get",
    component:ClientsGet,
    pdf:false,
    excel:false,
    add:"/users/add/0",
    menu:false,
  }
  // {
  //   name:"Perfil",
  //   slug:"/users/edit",
  //   module:"users",
  //   method:"get",
  //   component:UsersGet,
  //   pdf:false,
  //   excel:false,
  //   add:"/users/add/0",
  //   menu:true,
  // }
  // ,{
  //   name:"Mi Cuenta",
  //   slug:"/account",
  //   module:"user",
  //   method:"account",
  //   component:UserAccount
  // }
  ,{
    name:"Cerrar Sesión",
    slug:"/admin/user/exit/:id",
    module:"user",
    method:"exit",
    component:UserExit,
    pdf:false,
    excel:false,
    menu:true,
  },
]
