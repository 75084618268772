import * as React from 'react';
import {useState,useEffect} from 'react';
import StateContext from '../../helpers/ContextState';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Loading from '../common/Loading';
import Tabs from '../common/Tabs';
import Select from '../common/Select';
import ListEquipment from '../common/equipment/ListEquipment';
import ListOrders from '../common/orders/list';
import BtnSubmit from '../common/BtnSubmit';
import {
          inputs,
          useInputs
        } from '../../helpers/useInputs';

let   getInit
const btnSubmitId    =   'btnSubmitId'



const App = ({usePrivileges})=>{

  const context  = React.useContext(StateContext);
  const [loading, setLoading]     = useState(true);
  const [data, setData]           = useState({});

  const [roles, setRoles]         = useState([]);

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        if (response.data.rolesAll) {
          setRoles(response.data.rolesAll)
        }
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  },[context.onSubmit]);

  const onSubmit=(e)=>{
    e.preventDefault()
    context.post('api/users/assignRole',{...inputs,id:data.token},false,true).then((response)=>{

    })
  }

  const UserComponent=()=>{
    return  <form onSubmit={onSubmit}>
                <Paper sx={{ p: 2, }}>
                  <Select data={roles}
                          name="userType"
                          defaultValue={data.userType}
                          placeholder="Perfil de usuario"
                          inputs={inputs}
                          setInputs={useInputs}
                          required={true}
                          label="Perfil de usuario"
                          value={data.userType} />
                  <Typography variant="h6" align="center">
                    <b>{data.name}</b>
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    <b>{data.email?'('+data.email+')':false}</b>
                  </Typography>
                  <Typography  variant="subtitle2" align="center">
                    {data.phone}
                  </Typography>
                </Paper>
                <BtnSubmit id={btnSubmitId}/>
            </form>
  }

  return    <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>
                                    <Grid item xs={12} sx={{ mt: 1, }}>
                                      <Grid container justifyContent="center" spacing={2}>
                                          <Grid item xs={12} sm={12} md={11}>
                                            <Paper sx={{ p: 0, }}>
                                              <Tabs items={[
                                                              {label:"Usuario",component:<UserComponent/>},
                                                              {label:"Equipos",component:<ListEquipment getInit={getInit} client_id={data.id} id={data.token}/>},
                                                              {label:"Servicios",component:<ListOrders getInit={getInit} client_id={data.id} endpoint={"api/orders/byuserid/"+data.token}/>},
                                                            ]}/>

                                            </Paper>
                                          </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>}
            </>

}
export default App
