import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import UploadDocument    from '../../../../components/common/UploadDocument';
import doc_front    from '../../../../assets/images/doc_front.png';
import doc_back    from '../../../../assets/images/doc_back.png';
import doc_buy    from '../../../../assets/images/doc_buy.png';


const documents  = [
  {
    label:"Fotocopia Cédula frente",
    sublabel:"Formatos permitidos, PDF e imagen con un peso máximo de 6MB",
    name:"img_identification",
    src:doc_front,
  },
  {
    label:"Fotocopia cédula respaldos",
    sublabel:"Formatos permitidos, PDF e imagen con un peso máximo de 6MB",
    name:"img_identification_back",
    src:doc_back,
  },
  {
    label:"Desprendible nomina",
    sublabel:"Formatos permitidos, PDF e imagen con un peso máximo de 6MB",
    name:"img_removable_payroll",
    src:doc_buy,
  },
]
const App=(props)=>{

  React.useEffect(()=>{
    if (props.submit) {
      props.submitResponse(props.inputs)
      props.setSubmit(false)
    }
  },[props])

  return  <Box>
            <Grid container spacing={2} className="card-">
              <Grid item xs={12} className="h1 text-center2 p-2">
                Soportes de usuario
              </Grid>
              {documents.map((row,key)=>{
                row.id  = props.inputs.token
                return  <Grid item xs={12} md={4} key={key} >
                          <UploadDocument {...row} {...props}/>
                        </Grid>
              })}

            </Grid>
          </Box>
}

export default App
