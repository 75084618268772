import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Grid } from '@mui/material'
//import { NavLink } from "react-router-dom";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.data.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return  <>
            {
              props.data && props.data.length>0?<>
                <Grid container spacing={2} sx={{ pt: { xs:0 , md: 0 },
                                                  pb: { xs:0 , md: 0 },
                                                  mt: { xs:5 , md: 0 },
                                                  height: { xs:130 , md: 710 },
                                                }}>
                  <Grid  item xs={12}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents>
                          {props.data.map((step, index) => (

                            <div key={index}>
                              {Math.abs(activeStep - index) <= 2 ? (
                                  <>
                                      <Box
                                      component="img"
                                      sx={{
                                      display: 'block',
                                      overflow: 'hidden',
                                      width: '100%',
                                      }}
                                      src={step.image_desktop}
                                      alt={step.text_2}
                                      />
                                  </>
                              ) : null}
                            </div>
                          ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                      steps={maxSteps}
                      position="static"
                      activeStep={activeStep}
                      nextButton={
                        <Button
                          size="small"
                          onClick={handleNext}
                          disabled={activeStep === maxSteps - 1}
                        >
                          Siguiente
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      }
                      backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                          Anterior
                        </Button>
                      }
                    />
                  </Grid>
                </Grid>
              </>:<>

              </>
            }
          </>

}

export default SwipeableTextMobileStepper;
