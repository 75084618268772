import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {modules,modules_menu} from '../../helpers/modules';
import { Link } from "react-router-dom";
import ItemSubMenu from './ItemSubMenu';
//const pages = ['Products', 'Pricing', 'Blog'];

const ResponsiveAppBar = ({position,user}) => {
  const [anchorElNav, setAnchorElNav]     =   React.useState(null);
  const [anchorElUser, setAnchorElUser]   =   React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (row) => {
    seo(row)
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (row) => {
    seo(row)
    setAnchorElUser(null);
  };

  const seo=(row)=>{
    document.title =  process.env.REACT_APP_NAME+" - "+row.name
  }

  return (
    <AppBar position={position} color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <MenuItem to="/"
                    className="text-white"
                    component={Link}
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                    }}>
            <Typography
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {process.env.REACT_APP_NAME}
            </Typography>
          </MenuItem>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {
                modules.map((page,key) =>
                  page.items===undefined && page.menu?<MenuItem key={key}
                                      component={Link}
                                      to={page.slug}
                                      onClick={()=>handleCloseNavMenu(page)}>
                              <Typography textAlign="center">{page.name}</Typography>
                            </MenuItem>:page.items!==undefined && page.menu?<ItemSubMenu page={page} handleCloseNavMenu={handleCloseNavMenu} label={page.name} data={page.items} key={key}/>:false)
              }
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {process.env.REACT_APP_NAME}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {modules.map((page,key) =>
                page.menu===false?<div key={key}></div>:
                page.items===undefined?<MenuItem key={key}
                                      component={Link}
                                      to={page.slug}
                                      onClick={()=>handleCloseUserMenu(page)}>

                              {page.Icon?<page.Icon></page.Icon>:false}
                              <Typography textAlign="center">
                                {page.name}
                              </Typography>
                            </MenuItem>:<ItemSubMenu page={page} handleCloseNavMenu={handleCloseNavMenu} label={page.name} data={page.items} key={key}></ItemSubMenu>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Mi Perfil">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} src={user&&user.avatar?user.avatar:"https://www.sanboni.edu.co/onu/wp-content/uploads/avatar-mujer.png"} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {
                modules_menu.map((setting,key) => {
                    if (setting.menu) {
                      return  <MenuItem key={key}
                                        component={Link}
                                        to={setting.slug.replace('/:id', '')+'/'+user.token}
                                        onClick={()=>handleCloseUserMenu(setting)}>
                                {setting.Icon?<setting.Icon></setting.Icon>:false}
                                <Typography textAlign="center">{setting.name}</Typography>
                              </MenuItem>
                    }else {
                      return false
                    }
                  }
                )
            }

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
