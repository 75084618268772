import * as React from 'react';
import Grid from '@mui/material/Grid';
import Table from '../Table';
import IconAddOrder from './IconAddOrder';
const td  = [
              {
                label:"Estatus",
                value:"orders_assignments_status_string",
                align:"left"
              },
              {
                label:"Cliente",
                value:"client_name",
                align:"left"
              },
              {
                label:"Técnico",
                value:"technician_name",
                align:"left"
              },
              {
                label:"Equipo",
                value:"equipment_name",
                align:"left"
              },
              {
                label:"Fecha",
                value:"order_date_string",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["activity"]
              },
            ]

const App = ({usePrivileges,id,client_id,getInit,endpoint})=>{
  const [reload, setReload]   = React.useState(false);
  return  <Grid container
                component="main"
                direction="row"
                sx={{ minHeight: '75vh', }}
                justifyContent="center"
                >

            <Grid item xs={11}>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
                  <Table add={<IconAddOrder setReload={setReload} client_id={client_id} id={id}/>} reload={reload} setReload={setReload} td={td} endpoint={endpoint?endpoint:false}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
