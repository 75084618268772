import React, {useState, useRef, useMemo} from 'react';
import JoditEditor from "jodit-react";
import 'jodit/build/jodit.min.css';

const data_basic  = {
  readonly: false,
  placeholder: 'Start typings...',
  height:400,
  uploader: {
      insertImageAsBase64URI: true
  },
  imageDefaultWidth: 100,
  enableDragAndDropFileToEditor: true,
}

const Example = ({placeholder,name,inputs,setInputs}) => {
	const editor = useRef(null)
	const [content, setContent] = useState(inputs[name])

	const config = useMemo(()=>data_basic, [])

  const onEditorStateChange=(newContent) =>  {
    setContent(newContent);
    if (name && inputs && setInputs) {
      let inputs_         =   {...inputs}
          inputs_[name]   =   newContent
      setInputs(inputs_)
    }
  };

	return (
            <JoditEditor
                          ref={editor}
                          value={content}
                          config={config}
                          tabIndex={1}
                          height={200}
                          onBlur={newContent => onEditorStateChange(newContent)}
                          onChange={newContent => {}}/>
          );
}

export default Example
