import React from 'react';
import Grid from '@mui/material/Grid';
//import Box from '@mui/material/Box';
//import Paper    from '@mui/material/Paper';
import Carousel    from './components/Carousel2';
import MiniCarousel    from './components/MiniCarousel';
import StateContext from '../helpers/ContextState';
import Typography from '@mui/material/Typography';
import Loading from '../components/common/Loading01';
import { useLocation } from "react-router-dom";
const parse = require('html-react-parser');

let getInit=false

const App=()=>{
  const location                =   useLocation();
  const context                 =   React.useContext(StateContext);
  const [data,setData]          =   React.useState({})
  const [gallery, setGallery]   =   React.useState([]);
  const [loading,setLoading]    =   React.useState(true)
  const [loading2,setLoading2]  =   React.useState(false)

  const [news,setNews]                =   React.useState(false)
  const [newsTitle,setNewsTitle]      =   React.useState(false)
  const [events,setEvents]            =   React.useState(false)
  const [eventsTitle,setEventsTitle]  =   React.useState(false)
  const [videos,setVideos]            =   React.useState(false)


  getInit=()=>{
    setData({})
    setLoading(true)
    context.get('api/web/cms/home',{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        setLoading(false)
      }
      if (response.data && response.data.gallery) {
        setGallery(JSON.parse(response.data.gallery))
      }

      if (response.data.active_news==='Mostrar Noticias' || response.data.active_video==='Mostrar Video') {
        setLoading2(true)
        context.get('api/web/cms/attachments/'+response.data.component_id,{...context.user},false,false).then((response)=>{
          if (response.data && response.data.news && response.data.news.gallery) {

            let gallery = JSON.parse(response.data.news.gallery)
            let items   = []
            if (gallery)  {
              items     = [{  name:response.data.news.title,
                              url:gallery[0].url,
                              href:"/noticias"}]
            }
            setNewsTitle(response.data.news.title)
            setNews(items)
          }
          if (response.data && response.data.events) {
            let gallery = JSON.parse(response.data.events.gallery)
            let items   = []
            if (gallery)  {
              items     = [{  name:response.data.events.title,
                              url:gallery[0].url,
                              href:"/noticias"}]
            }
            setEventsTitle(response.data.events.title)
            setEvents(items)
          }
          if (response.data && response.data.videos) {
            let items = []
                response.data.videos.map((row,key)=>{
                  const gallery = JSON.parse(row.gallery)
                  let img       = ""
                  let href      = ""
                  if (gallery && gallery[0] && gallery[0].url) {
                    img = gallery[0].url
                  }
                  if (gallery && gallery[0] && gallery[0].href) {
                    href = gallery[0].href
                  }
                  return items.push({name:row.title,url:img,href:href})
                })
            setVideos(items)
          }
          setLoading2("end")
        })
      }

    })
  }

  React.useEffect(()=>{
    getInit()
  },[location])


  return  <>
            <Carousel data={gallery}/>
            <Grid container justifyContent="center" spacing={2} sx={{mb:10}}>
              {
                !loading?<>
                  <Grid item xs={11} sx={{ mt: 8, }}>

                      <Typography variant="h4" component="b">
                        {data.component_id}
                      </Typography>
                      <Grid className="text-complete">
                        {
                          data.content?parse(data.content):""
                        }
                      </Grid>
                  </Grid>
                </>:<>
                  <Loading/>
                </>
              }
              {
                loading2==='end'?<>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="h4" component="b" className="d-block border-bottom-primary">
                          Actualidad
                        </Typography>
                        <Grid>
                          <Typography variant="h6" component="b" className="d-block bg-primary p-1" sx={{mt:1}}>
                            {newsTitle}
                          </Typography>
                          {
                            news?<>
                              <MiniCarousel data={news}></MiniCarousel>
                            </>:false //aquí colocar una imagen por defecto
                          }
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" component="b" className="d-block border-bottom-primary">
                          Eventos
                        </Typography>
                        <Grid>
                          <Typography variant="h6" component="b" className="d-block bg-primary p-1" sx={{mt:1}}>
                            {eventsTitle}
                          </Typography>
                          {
                            events?<>
                              <MiniCarousel data={events}></MiniCarousel>
                            </>:false //aquí colocar una imagen por defecto
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h4" component="b" className="d-block border-bottom-primary">
                        Videos
                      </Typography>
                      <Grid>
                        {
                          videos?<>
                            <MiniCarousel data={videos}></MiniCarousel>
                          </>:false //aquí colocar una imagen por defecto
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>
                </>:loading2===true?<>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Loading/>
                      </Grid>
                      <Grid item xs={6}>
                        <Loading/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Loading/>
                      </Grid>
                      <Grid item xs={6}>
                        <Loading/>
                      </Grid>
                    </Grid>
                  </Grid>
                </>:false
              }
            </Grid>
          </>
}
export default App;
