import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Input    from '../../../../components/common/Input';
import BtnSubmit from '../../../../components/common/BtnSubmit';
import Loading    from '../../../../components/common/Loading03';
import DatePicker from '../../../../components/common/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import StateContext from '../../../../helpers/ContextState';
//import { useNavigate } from "react-router-dom";

const btnSubmitId    =   'btnSubmitId3'

const App=(props)=>{

  //const navigate = useNavigate();
  const context  = React.useContext(StateContext);
  const [inputs, setInputs]     = React.useState({});
  const [loading, setLoading]   = React.useState(false);


  React.useEffect(()=>{
    if (props.inputs && props.inputs.id && !inputs.id) {
      setLoading(true)
      setInputs(props.inputs)
      setTimeout(()=>{
        setLoading(false)
      },2000)
    }
  },[props,inputs])

  React.useEffect(()=>{
    if (props.submit) {
      document.getElementById(btnSubmitId).click();
      props.setSubmit(false)
    }
  },[props])

  const onChange=(e)=>{
      inputs[e.target.name]  = e.target.value
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    inputs.step = props.step;
    context.post(false,{...inputs},false,true).then((response)=>{
      if (response && response.data) {
        props.submitResponse(response.data)
        setInputs(response.data)
        if (response.data.token) {
        //  navigate('/web/formulario/afiliate/'+response.data.token)
        }else if (response.data.id) {

        }
      }
    })
  }

  return  <Box>
            <form onSubmit={onSubmit} id={"form2"}>
              <BtnSubmit id={btnSubmitId}/>
              <Grid container spacing={2} className="card-">
                <Grid item xs={12} className="h1 text-center2 p-2">
                  Datos del cónyugue
                </Grid>
                {loading?<Loading/>:<>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            label="Nombres"
                            name="spouse_names"
                            defaultValue={inputs.spouse_names}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountCircle />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            label="Apellidos"
                            name="surnames_spouse"
                            defaultValue={inputs.surnames_spouse}
                            size="small"
                            onChange={onChange}
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountCircle />
                                              </InputAdornment>
                                            ),
                                        }}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input  margin="normal"
                            type="text"
                            label="Nivel de Escolaridad"
                            name="education_level_spouse"
                            defaultValue={inputs.education_level_spouse}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <SchoolIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DatePicker margin="normal"
                                size="small"
                                inputs={inputs}
                                label="Fecha de nacimiento"
                                name="birth_spouse"
                                defaultValue={inputs.birth_spouse}
                                onChange={onChange}/>
                  </Grid>
                </>}
              </Grid>
            </form>
          </Box>
}

export default App
