import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Grid } from '@mui/material'

function Example(props)
{
    return (
            <>
              {
                props.data && props.data.length>0?
                <Grid container spacing={2} sx={{ pt: 5 , pb:5  ,height:480}}>
                  <Grid  item xs={12}>
                    <Carousel navButtonsAlwaysInvisible={false}>
                      {props.data.map( (item, i) => <Item key={i} item={item} /> )}
                    </Carousel>
                  </Grid>
                </Grid>:false
              }
            </>
    )
}

function Item(props)
{
    return  <Grid>
              <img className="img-fluid" src={props.item.url} alt="Programandoweb"/>
            </Grid>
}

export default Example
