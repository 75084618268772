import React from 'react';
import Grid from '@mui/material/Grid';
import StateContext from '../../helpers/ContextState';
import Loading from '../../components/common/Loading01';
import { useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Carousel from '../../web/components/Carousel3';

let getInit=false

const App=()=>{
  const location              =   useLocation();
  const context               =   React.useContext(StateContext);
  const [data,setData]        =   React.useState({})
  const [loading,setLoading]  =   React.useState(true)
  getInit=()=>{
    setData({})
    setLoading(true)
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        setLoading(false)
      }
    })
  }

  React.useEffect(()=>{
    getInit()
  },[location])
  return    <Grid container justifyContent="center" spacing={2} sx={{mb:10}}>
              {
                !loading?<>

                  <Grid item xs={11} sx={{ mt: 8, }}>
                      <h1>GIMNASIO BODYTECH</h1>
                      {
                        data.beneficios && data.beneficios.slider?<>
                          <Carousel data={data.beneficios.slider}/>
                        </>:false
                      }
                      <h4>Beneficios</h4>

                      {data.clases?<>
                        <Grid container justifyContent="start" spacing={2}>
                          {data.clases.map((row,key)=>{
                            if (!row.image) {
                              return false
                            }
                            return  <Grid item xs={12} md={3} key={key}>
                                      <Card elevation={3}>
                                        <CardMedia
                                            component="img"
                                            height="194"
                                            image={row.image}
                                            alt="Programandoweb"
                                        />
                                        <CardContent>
                                          <Typography variant="body2" color="text.secondary">
                                            {row.category}
                                            <br/>
                                            {row.name}
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                          })}

                        </Grid>

                      </>:false}

                  </Grid>
                  <Grid item xs={11} sx={{ mt: 4, }}>
                      <h1>INFO BODY</h1>

                      {data.beneficios && data.beneficios.objectives?<>
                        <Grid container justifyContent="start" spacing={2}>
                          {data.beneficios.objectives.map((row,key)=>{
                            return  <Grid item xs={12} md={3} key={key}>
                                      <Card elevation={3}>
                                        <CardHeader
                                          subheader=  {row.url_text}
                                        />
                                        <CardMedia
                                            component="img"
                                            height="194"
                                            image={row.image}
                                            alt="Paella dish"
                                        />
                                        <CardContent>
                                          <Typography variant="body2" color="text.secondary">
                                            {row.title}
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                          })}

                        </Grid>

                      </>:false}

                  </Grid>
                </>:<>
                  <Loading/>
                </>
              }
            </Grid>
}

export default App
