import * as React from 'react';
import Grid from '@mui/material/Grid';
import Table from '../Table';
import IconAddEquipment from './IconAddEquipment';

const td  = [
              {
                label:"Equipo",
                value:"name",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["view"]
              },
            ]

const App = ({usePrivileges,id,client_id,getInit})=>{
  const [reload, setReload]   = React.useState(false);
  return  <Grid container
                component="main"
                direction="row"
                className="min-height-80"
                justifyContent="center"
                >

            <Grid item xs={11}>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
                  <Table add={<IconAddEquipment setReload={setReload} client_id={client_id} id={id}/>} reload={reload} setReload={setReload} endpoint={"api/equipment/list/"+id} td={td}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
