import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input    from '../../../../components/common/Input';
import BtnSubmit from '../../../../components/common/BtnSubmit';
import Loading    from '../../../../components/common/Loading03';
import DatePicker from '../../../../components/common/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import StateContext from '../../../../helpers/ContextState';
//import { useNavigate } from "react-router-dom";

const btnSubmitId    =   'btnSubmitId3'

let getInit=false

const App=(props)=>{

  //const navigate = useNavigate();
  const context  = React.useContext(StateContext);
  const [inputs, setInputs]     = React.useState({});
  const [loading, setLoading]   = React.useState(false);
  const [data, setData]   = React.useState([]);


  React.useEffect(()=>{
    if (props.inputs && props.inputs.id && !inputs.id) {
      setLoading(true)
      setInputs({id:props.inputs.id,client_id:props.inputs.token})
      setTimeout(()=>{
        setLoading(false)
      },2000)
    }
  },[props,inputs])

  React.useEffect(()=>{
    if (props.submit) {
      document.getElementById(btnSubmitId).click();
      props.submitResponse(props.inputs)
      props.setSubmit(false)
    }
  },[props])

  React.useEffect(()=>{
    getInit()
  },[])



  const onChange=(e)=>{
      inputs[e.target.name]  = e.target.value
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    //props.submitResponse(props.inputs)
    handleOnClick()
  }

  const handleOnClick=()=>{
    setLoading(true)
    context.post("api/web/formulario/afiliate_beneficiaries",{...inputs,client_id:props.inputs.token},false,true).then((response)=>{
      if (response && response.data) {
        setData(response.data)
        setLoading(false)
      }
    })
  }

  getInit=()=>{
    context.get("api/web/formulario/afiliate_beneficiaries/"+props.inputs.token,{},false,false).then((response)=>{
      if (response && response.data) {
        setData(response.data)
      }
    })
  }

  return  <Box>
            <form onSubmit={onSubmit} id={"form2"}>
              <BtnSubmit id={btnSubmitId}/>
              <Grid container spacing={2} className="card-">
                <Grid item xs={12} className="h1 text-center2 p-2">
                  Datos de beneficiario(s)
                </Grid>
                {loading?<Loading/>:<>
                  <Grid item xs={12} md={4}>
                    <Input  margin="normal"
                            type="text"
                            label="Nombres y Apellidos"
                            name="name"
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountCircle />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Input  margin="normal"
                            type="text"
                            label="Identificación"
                            name="identification"
                            defaultValue={inputs.identification}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <SchoolIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Input  margin="normal"
                            type="number"
                            label="Porcentaje"
                            name="porcentaje"
                            required={true}
                            min={1}
                            max={100}
                            defaultValue={inputs.porcentaje}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountCircle />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Input  margin="normal"
                            type="text"
                            label="Parentesco"
                            name="relationship"
                            defaultValue={inputs.relationship}
                            onChange={onChange}
                            size="small"
                            InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <SchoolIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <DatePicker margin="normal"
                                size="small"
                                inputs={inputs}
                                label="Fecha de nacimiento"
                                name="date_birth"
                                onChange={onChange}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button fullWidth
                            variant="contained"
                            type="submit"
                    >
                      Agregar
                    </Button>
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container className="bg-primary p-1">
                      <Grid item xs={12} md={4} >
                        Beneficiario
                      </Grid>
                      <Grid item xs={12} md={2}>
                        Identificación
                      </Grid>
                      <Grid item xs={12} md={2}>
                        Porcentaje
                      </Grid>
                      <Grid item xs={12} md={2}>
                        Parentesco
                      </Grid>
                      <Grid item xs={12} md={2}>
                        Fecha nacimiento
                      </Grid>
                    </Grid>
                    {data.map((row,key)=>{

                      return  <Grid container key={key}>
                                <Grid item xs={12} md={4}>
                                  {row.name}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  {row.identification}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  {row.porcentaje}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  {row.relationship}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  {row.date_birth}
                                </Grid>
                              </Grid>

                    })}

                  </Grid>
                </>}
              </Grid>
            </form>
          </Box>
}

export default App
