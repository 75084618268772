import * as React from 'react';
import {useState,useEffect} from 'react';
import StateContext from '../../helpers/ContextState';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Loading from '../common/Loading';
import pdf_img from '../../assets/images/pdf.jpg';

let getInit

const App = ({usePrivileges})=>{
  const context  = React.useContext(StateContext);
  const [loading, setLoading]     = useState(false);
  const [data, setData]           = useState({});

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{
    setLoading(true)
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
      }
      setLoading(false)
    })
  }

  return    <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>
                                    <Grid item xs={12} sx={{ mt: 10, mb:6 }}>
                                      <Grid container justifyContent="center" spacing={2}>
                                          <Grid item xs={11} >
                                            <Paper sx={{ p: 2, }}>
                                              <Typography variant="h6">
                                                Datos del cliente <b>{data.name} {data.surnames}</b>
                                              </Typography>
                                              <Grid container spacing={2}>
                                                {Object.entries(data).length>0?<>
                                                  {
                                                    Object.entries(data).map((row,key)=>{
                                                      if (
                                                            row[0]!=='token' &&
                                                            row[0]!=='img_identification' &&
                                                            row[0]!=='img_identification_back' &&
                                                            row[0]!=='img_removable_payroll' &&
                                                            row[0]!=='created_at' &&
                                                            row[0]!=='updated_at'  &&
                                                            row[0]!=='status' &&
                                                            row[0]!=='id'
                                                          ) {
                                                        return  <Grid item xs={4} key={key}>
                                                                  <Grid><b>{row[0]}</b></Grid>
                                                                  <Grid>{row[1]}</Grid>
                                                                </Grid>
                                                      }else if (row[0]==='img_identification' && row[1]) {
                                                        return  <Grid item xs={4} key={key}>
                                                                  <Grid>Identificación</Grid>
                                                                  <Grid>
                                                                    {row[1].includes(".pdf")?<>
                                                                      <a href={row[1]} target={"_blank"} rel="noreferrer">
                                                                        <img src={pdf_img} alt="Programandoweb" className="img-fluid cursor-pointer"/>
                                                                      </a>
                                                                      </>:<>
                                                                      <img className="img-fluid" src={row[1]} alt="pgrw"/>
                                                                    </>}
                                                                  </Grid>
                                                                </Grid>
                                                      }else if (row[0]==='img_identification_back' && row[1]) {
                                                        return  <Grid item xs={4} key={key}>
                                                                  <Grid>Identificación trasera</Grid>
                                                                  <Grid>
                                                                    {row[1].includes(".pdf")?<>
                                                                      <a href={row[1]} target={"_blank"} rel="noreferrer">
                                                                        <img src={pdf_img} alt="Programandoweb" className="img-fluid cursor-pointer"/>
                                                                      </a>
                                                                      </>:<>
                                                                      <img className="img-fluid" src={row[1]} alt="pgrw"/>
                                                                    </>}
                                                                  </Grid>
                                                                </Grid>
                                                      }else if (row[0]==='img_removable_payroll' && row[1]) {
                                                        return  <Grid item xs={4} key={key}>
                                                                  <Grid>Desprendible nomina</Grid>
                                                                  {console.log(row[1])}
                                                                  <Grid>
                                                                    {row[1].includes(".pdf") || row[1].includes(".PDF")?<>
                                                                      <a href={row[1]} target={"_blank"} rel="noreferrer">
                                                                        <img src={pdf_img} alt="Programandoweb" className="img-fluid cursor-pointer"/>
                                                                      </a>
                                                                      </>:<>
                                                                      <img className="img-fluid" src={row[1]} alt="pgrw"/>
                                                                    </>}
                                                                  </Grid>
                                                                </Grid>
                                                      }else {
                                                        return false
                                                      }
                                                    })
                                                  }
                                                </>:false}
                                              </Grid>
                                            </Paper>
                                          </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>}
            </>

}
export default App
